import React, { FC, memo, useCallback, useEffect, useState } from 'react';

import {
  AggregateAlertsBy,
  AggregatedAlertDetails,
  SortOrder as NotificationSortOrder,
} from '@ariksa/notification';
import {
  AlertsApiGetAggregatedAlertsRequest,
  Severity,
} from '@ariksa/notification/api';
import {
  Box,
  Center,
  Flex,
  HStack,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import {
  each,
  entries,
  forEach,
  isEmpty,
  isEqual,
  map,
  slice,
  split,
  toArray,
  toUpper,
} from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';
import { INIT_PAGE_INFO } from 'services/utils/constants';
import { Optional } from 'types/utils';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import {
  Sorted,
  SortOrder,
  TableColumnProps,
} from 'components/DataDisplay/NewTable/types';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import {
  AddExceptionActionButton,
  BellIcon,
  CDashboard,
  getIcon,
  getProviderName,
  LineChartWithoutAxisIcon,
  PageHeaderWithIcon,
  renderSeverityCountBubble,
  renderTableHeaderWithLoader,
  Tag,
  WithResourceIcon,
} from 'components/index';
import { ActiveResourceProvider } from 'containers/ActiveCloudResource/context/ActiveResourceProvider';
import { useEnvironmentOptions } from 'containers/App/hooks/useEnvironmentOptions';
import { ResourceTypeIconTooltip } from 'containers/App/hooks/useResourceType';
import {
  selectActiveCloudAccount,
  selectActiveEnvironment,
} from 'containers/App/selectors';
import { useIndividualAlertsTableColumns } from 'containers/Findings/Alerts/Components/AlertsTable/hooks/useIndividualAlertsTableColumns';
import { AlertTableFilter } from 'containers/Findings/FindingsOverview/Components/AlertTableFilter';
import { AggregatedAlertDrawer } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Aggregated/AggregatedAlertDrawer';
import { useUserDashboard } from 'containers/Findings/FindingsOverview/hooks/useUserDashboard';
import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';
import { actions } from 'containers/Findings/FindingsOverview/slice';
import {
  CloudAccountName,
  useCloudAccountId,
} from 'containers/Setup/CloudAccounts/utils';
import { useSearchParams } from 'hooks/useSearchParams';
import { toTitleCase } from 'utils/string';

interface Props {
  isExpanded: boolean;

  setIsExpanded(val: boolean);

  title: string;
}

export const AggregatedAlertTable: FC<Props> = memo(props => {
  const { isExpanded, setIsExpanded, title } = props;
  const {
    aggregatedAlerts,
    currentCategory,
    aggregatedAlertCompliance,
  } = useSelector(selectFindingsOverview);

  const dispatch = useDispatch();
  const params = useSearchParams<{
    sortField: string;
    sortOrder: SortOrder;
    group_id: string;
  }>();

  const { accountId } = useSelector(selectActiveCloudAccount);
  const { environmentId } = useSelector(selectActiveEnvironment);
  const { renderStackedCloudAccountWithCloud } = useCloudAccountId();

  const alertDrawer = useDisclosure();
  const { blueprint } = useIndividualAlertsTableColumns();
  const { environmentMapping } = useEnvironmentOptions();
  const { accountMapping } = useCloudAccountId();
  const navigate = useNavigate();
  const location = useLocation();
  const { dashboardWidgets } = useUserDashboard();

  const [aggregatedAlert, setAggregatedAlert] = useState<
    Optional<AggregatedAlertDetails>
  >(undefined);

  const [tabIndex, setTabIndex] = useState(0);
  const [sortByField, setSortByField] = useState<Sorted>({} as Sorted);

  useEffect(() => {
    if (!!params?.sortOrder && !!params?.sortField)
      setSortByField({
        sortOrder: params?.sortOrder as SortOrder,
        sortField: params?.sortField,
      });
  }, [params?.sortOrder, params?.sortField]);

  const getAggregatedAlerts = useCallback(
    (payload, pageInfo) => {
      dispatch(
        actions.loadAlertsGroupedByAlertId({
          q: payload,
          page: pageInfo,
          onSuccess: res => {
            const ruleIds = new Set();
            forEach(res?.aggregated_alerts, o => {
              forEach(o.alert_rule_ids, id => ruleIds.add(id));
            });
            !isEmpty(ruleIds) &&
              dispatch(
                actions.getAggregatedAlertCompliance({
                  q: {
                    getRuleComplianceStandardRequest: {
                      rules: toArray(ruleIds) as string[],
                    },
                  },
                }),
              );
          },
        }),
      );
    },
    [dispatch],
  );

  const loadAlertsGroupedByRuleId = useCallback(
    (pageInfo = INIT_PAGE_INFO) => {
      let payload: AlertsApiGetAggregatedAlertsRequest = {
        environmentId: environmentId,
        accountId,
        pageNumber: pageInfo.page_number,
        pageSize: pageInfo.page_size,
        alertCategories: !!params?.alertCategory
          ? split(params?.alertCategory, ',')
          : undefined,
        aggregateBy: AggregateAlertsBy.GroupId,
        categoryClass:
          currentCategory === 'AllFindings'
            ? undefined
            : (currentCategory as string),
        severities: !!params?.severity
          ? (split(params?.severity, ',') as Severity[])
          : undefined,
        resourceTypes: !!params?.resourceType
          ? (split(params?.resourceType, ',') as Severity[])
          : undefined,
        sortField: !!sortByField?.sortField
          ? sortByField?.sortField
          : undefined,
        sortOrder: !!sortByField?.sortOrder
          ? (toUpper(sortByField?.sortOrder) as NotificationSortOrder)
          : undefined,
        groupIds: !!params.group_id ? [params.group_id] : undefined,
      };
      getAggregatedAlerts(payload, pageInfo);
    },
    [
      environmentId,
      accountId,
      getAggregatedAlerts,
      currentCategory,
      sortByField,
      params.group_id,
      params?.alertCategory,
      params?.resourceType,
      params?.severity,
      location,
    ],
  );

  useEffect(() => {
    loadAlertsGroupedByRuleId();
  }, [loadAlertsGroupedByRuleId]);

  const renderStackedEnvironment = (uuid, row) => {
    const env = environmentMapping?.[uuid];
    const accounts = row?.account_id_list?.length;
    const clouds = new Map<string, any[]>();
    each(row?.account_id_list, o => {
      const cloudType = accountMapping[o]?.cloud_type ?? '';
      const accounts = clouds.get(cloudType) || [];
      accounts.push(o);
      clouds.set(cloudType, accounts);
    });

    // const clouds = new Set();
    // each(row?.account_id_list, o => clouds.add(accountMapping[o]?.cloud_type));
    //
    // const renderTooltipField = (label, value) => (
    //   <HStack spacing={1} align={'start'}>
    //     <Box color="orange">{label}:</Box>
    //     <Box flex={1}>{value}</Box>
    //   </HStack>
    // );
    //
    // const accountCount = new Map();
    // each(row?.account_id_list, o => {
    //   accountCount.set(
    //     accountMapping[o]?.cloud_type,
    //     (accountCount.get(accountMapping[o]?.cloud_type) || 0) + 1,
    //   );
    // });

    return (
      <CustomTooltip
        label={
          <Stack spacing={4}>
            {Array.from(clouds.entries()).map(([k, v]) => {
              return (
                <Stack>
                  <Box color={'orange'}>
                    {getProviderName(k)}({v.length})
                  </Box>
                  <Stack>
                    {map(v, o => (
                      <CloudAccountName accountId={o} />
                    ))}
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        }
      >
        <Stack spacing={0}>
          <Box>
            {accounts} account{accounts === 1 ? '' : 's'}
          </Box>

          <HStack>
            {map(Array.from(clouds.keys()), o => (
              <Box boxSize={4}>{getIcon(o)}</Box>
            ))}
          </HStack>
        </Stack>
      </CustomTooltip>
    );
  };

  const renderCompliance = ({ row }) => {
    let compliance = new Set();
    forEach(row?.alert_rule_ids, o => {
      forEach(aggregatedAlertCompliance.data?.[o], c => compliance.add(c));
    });
    const items = toArray(compliance) as string[];
    const renderItem = items => (
      <HStack>
        {map(items, o => (
          <CustomTooltip label={o}>
            <Box boxSize={5}>{getIcon(o)}</Box>
          </CustomTooltip>
        ))}
      </HStack>
    );
    return (
      <Stack>
        {renderItem(slice(items, 0, 3))}
        {renderItem(slice(items, 3, 5))}
        {items.length > 5 && (
          <Tag
            label={'+' + (items.length - 5)}
            styles={{ tag: { bg: 'primary' }, label: { color: 'white' } }}
          ></Tag>
        )}
      </Stack>
    );
  };

  const columns: TableColumnProps[] = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Finding</Box>,
      render: ({ row }) => {
        const type = row?.multi_resource
          ? 'CloudService'
          : row?.resource_types?.[0];
        return (
          <WithResourceIcon
            resourceType={type}
            bgColor={row?.multi_resource ? 'primary' : row?.resource_types?.[0]}
            iconTooltip={<ResourceTypeIconTooltip resourceType={type} />}
            tooltip={row.description}
          >
            {row.description}
          </WithResourceIcon>
        );
      },
      align: 'left',
    },
    blueprint,
    {
      header: accountId ? 'Account' : 'Environment',
      align: 'left',
      styles: {
        width: '40px',
        cell: { maxW: 50 },
      },
      render: ({ row }) => {
        return (
          <>
            {accountId
              ? renderStackedCloudAccountWithCloud(accountId)
              : renderStackedEnvironment(environmentId, row)}
          </>
        );
      },
    },
    {
      header: 'Severity',
      accessor: 'severity',
      align: 'left',
      render: ({ row }) => (
        <HStack spacing={2}>
          {entries({
            critical: row.severity.CRITICAL,
            high: row.severity.HIGH,
            medium: row.severity.MEDIUM,
            low: row.severity.LOW,
          }).map(
            ([v, k]) => !!k && <Flex>{renderSeverityCountBubble(v, k)}</Flex>,
          )}
        </HStack>
      ),
      styles: {
        width: '100px',
        cell: { maxW: '100px' },
      },
    },
    {
      header: 'Category',
      accessor: 'alert_category',
      align: 'left',
      render: ({ value }) => toTitleCase(value),
      styles: {
        width: '120px',
        cell: { maxW: '120px' },
      },
      sortKey: 'alert_category',
    },
    {
      header: renderTableHeaderWithLoader(
        'Compliance',
        aggregatedAlertCompliance.isLoading,
      ),
      align: 'left',
      render: renderCompliance,
      styles: {
        width: '130px',
        cell: { maxW: '130px' },
      },
    },
    /*...(!alertCategoryInfo
      ? [
          {
            header: 'Category',
            accessor: 'alert_category',
            align: 'left',
            render: ({ value }) => toTitleCase(value),
            styles: {
              width: '150px',
              cell: { maxW: '150px' },
            },
          },
        ]
      : [
          {
            header: renderTableHeaderWithLoader(
              'Compliance',
              aggregatedAlertCompliance.isLoading,
            ),
            align: 'left',
            render: renderCompliance,
            styles: {
              width: '180px',
              cell: { maxW: '180px' },
            },
          },
        ]),*/
    {
      header: 'Actions',
      align: 'center',
      styles: {
        width: '40px',
        cell: { maxW: 50 },
      },
      render: ({ row }) => {
        return (
          <Center>
            <AddExceptionActionButton aggregatedAlert={row} />
          </Center>
        );
      },
    },
  ];

  //const { downloadReport } = useAlertDownloader();

  const menuItems = [
    {
      key: 'alerts',
      label: 'Show individual alerts',
      icon: <BellIcon />,
      onClick: () =>
        navigate(
          '/findings/alerts' +
            (!!params.alertCategory
              ? '?alertCategory=' + params.alertCategory
              : ''),
        ),
    },
    {
      key: 'charts',
      label: 'Show historical information',
      icon: <LineChartWithoutAxisIcon p={0.5} />,
      onClick: () => setIsExpanded(!isExpanded),
    },
  ];

  return (
    <ActiveResourceProvider>
      <Stack h="full" spacing={4}>
        <HStack w="full" justify="space-between">
          <HStack spacing={4}>
            <PageHeaderWithIcon
              label={<Box>{title}</Box>}
              iconType={currentCategory as string}
              iconBgColor={currentCategory as string}
              reversed
            />
            <HStack spacing={1}>
              {map(menuItems, o => (
                <Box
                  boxSize={6}
                  borderRadius="full"
                  bg={o.key === 'charts' && !isExpanded ? 'primary' : 'gray.50'}
                  color={
                    o.key === 'charts' && !isExpanded ? 'white' : 'critical'
                  }
                  cursor="pointer"
                  onClick={o.onClick}
                >
                  <CustomTooltip label={o.label} w={32} textAlign="center">
                    <Center p={0.5}>{o.icon}</Center>
                  </CustomTooltip>
                </Box>
              ))}
            </HStack>
          </HStack>

          {isExpanded && <AlertTableFilter />}
        </HStack>

        {!isExpanded && <CDashboard dashboardWidgets={dashboardWidgets} />}

        <Stack w="full" flex={1} spacing={4}>
          {!isExpanded && (
            <Flex justifyContent="flex-end" w="full">
              <AlertTableFilter />
            </Flex>
          )}

          <Box flex={1}>
            <Table
              className="dashboard_aggregated_alerts_table"
              data={aggregatedAlerts.data}
              columns={columns}
              pagination={{
                pageInfo: aggregatedAlerts.page.info,
                totalCount: aggregatedAlerts.page.totalCount,
                onChange: loadAlertsGroupedByRuleId,
              }}
              isLoading={aggregatedAlerts.isLoading}
              styles={{ height: '32.8rem', header: { zIndex: 800 } }}
              headerClassName="dashboard_alerts_table"
              onRowClick={r => {
                setAggregatedAlert(r);
                alertDrawer.onOpen();
              }}
              isRowActive={r =>
                r.alert_rule_id === aggregatedAlert?.alert_category
              }
              cursor={'pointer'}
              sortBy={sortByField}
              onSort={sortInfo => {
                if (!isEqual(sortByField, sortInfo)) {
                  setSortByField(sortInfo);
                }
              }}
            />
          </Box>
        </Stack>

        {alertDrawer.isOpen && aggregatedAlert && (
          <AggregatedAlertDrawer
            alert={aggregatedAlert}
            isOpen={alertDrawer.isOpen}
            onClose={alertDrawer.onClose}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
          />
        )}
      </Stack>
    </ActiveResourceProvider>
  );
});
