import React, { useEffect, useRef, useState } from 'react';

import { NativeResources } from '@ariksa/inventory-core';
import { ResourceMapping } from '@ariksa/inventory-core/api';
import { Accordion, Box } from '@chakra-ui/react';

import {
  DetailsIdentifier,
  getIcon,
  StackedHeader,
} from 'components/DataDisplay';
import { IconTypes } from 'components/Icons';
import { Drawer } from 'components/Overlay';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { CloudAccountName } from 'containers/Setup/CloudAccounts/utils';
import { ResourceActions } from 'containers/Setup/Jit/Components/Accounts/ResourceActions';

import request from '../../../../../utils/request';

interface ResourceActionsProps {
  isOpen: boolean;
  onClose: () => void;
  resource: {
    cloud_type: string;
    id: string;
    name: string;
    type: string;
    cloud_id: string;
  };
}

export const ResourceActionsDrawer = (props: ResourceActionsProps) => {
  const { isOpen, onClose, resource } = props;
  const [type, setType] = useState<ResourceMapping>({} as ResourceMapping);
  const { getResourceType } = useResourceType();
  const ref = useRef(document.querySelector('.portal-container'));

  useEffect(() => {
    setType(getResourceType(resource.type) || ({} as ResourceMapping));
  }, [getResourceType]);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      header={
        <StackedHeader
          upper={<CloudAccountName accountId={resource?.id} />}
          lower={`${type.cloud_native_name} / ${type.cloud_agnostic_name}`}
          type={resource.type}
        />
      }
      body={
        <Box h="full" w="full" pt={3}>
          <DetailsIdentifier
            label="Cloud"
            value={<Box w={6}>{getIcon(resource.cloud_type)}</Box>}
          />
          <Accordion defaultIndex={[0]} allowMultiple>
            <MetaAccordionItem
              iconType={IconTypes.DataScan}
              label={'ACTIONS PERFORMED ON THIS RESOURCE DURING LAST 24 HOURS'}
              panel={<ResourceActions accountId={resource?.cloud_id ?? ''} />}
              iconBGColor="primary"
              height={'650px'}
            />
          </Accordion>
        </Box>
      }
      closeButton
      styles={{
        content: { maxW: '1300px' },
        drawer: { portalProps: { containerRef: ref as any } },
      }}
    />
  );
};
