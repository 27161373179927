import {
  ContextApiGetRiskContextResRequest,
  DashboardApiGetResourcesByImagesForDashboardRequest,
  InsightV2Pagination,
  ResourceVulnerabilities,
  RiskContextResponse,
  SunburstDashboard,
  VulnerabilitiesApiGetCveResourcesRequest,
  VulnerabilitiesApiGetPackageResourcesRequest,
  VulnerabilitiesApiGetPackageVulnerabilitiesRequest,
  VulnerabilitiesApiGetVulnerabilitiesRequest,
  VulnerabilitiesApiVulnerabilityInsightRequest,
  VulnerablePackageResponse,
} from '@ariksa/inventory-core/api';
import { ChartsApiGetVulnerabilitiesDetailsRequest } from '@ariksa/reporting';
import {
  BySourcesDashboard,
  CVEDetailPagination,
  DefaultApiGetBySourcesDashboardDataRequest,
  DefaultApiGetCvesByIdRequest,
  DefaultApiGetCvesRequest,
  DefaultApiGetEcrImageListRequest,
  DefaultApiGetVulnerabilityPriortizationDataRequest,
  ECRImageVisibilityDashboard,
  ScannerInfo,
  VulnerabilityPriortization,
} from '@ariksa/scan-analysis/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { forEach } from 'lodash';
import { QueryAction, QueryStatus } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';

import { Sorted } from 'components/DataDisplay/NewTable/types';
import { PageInfo, publishedAtOptions, SideNavKey } from 'components/index';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Tag container
export const initialState: ContainerState = {
  vulnerabilities: PagedQueryState.init([]),
  cveHosts: PagedQueryState.init([]),
  cves: PagedQueryState.init([], {
    page: { info: { page_number: 1, page_size: 100 }, totalCount: 0 },
  }),
  vulnerabilitiesBySeverity: QueryState.init({}),
  vulnerabilitiesBySeverityCounts: QueryState.init({}),

  hosts: QueryState.init({}),
  containers: QueryState.init([]),
  containerVulnerability: QueryState.init({}),
  searchTerm: '',
  tablesTabIndex: 0,
  sort: {
    sortOrder: 'desc',
    sortField: 'severity',
  },
  selectedTab: 'Overview',
  cveHostsRiskContext: QueryState.init({}),
  cveHostsVulnerabilities: {},
  scannerInfo: QueryState.init({}),
  publishedAt: publishedAtOptions[0],
  filters: {},
  cveDetails: QueryState.init({}),
  vulnerabilityPrioritization: QueryState.init(
    {} as VulnerabilityPriortization,
  ),
  vulnerabilitiesBySources: QueryState.init({} as BySourcesDashboard),
  vulnerableImages: QueryState.init({} as SunburstDashboard),
};

const vulnerabilitySlice = createSlice({
  name: 'vulnerability',
  initialState,
  reducers: {
    updateFilter(
      state,
      action: PayloadAction<{ field: string; value: string }>,
    ) {
      state.filters[action.payload.field] = action.payload.value;
    },

    resetFilters(state) {
      state.filters = initialState.filters;
    },
    getVulnerabilities(
      state,
      action: QueryAction<
        InsightV2Pagination,
        VulnerabilitiesApiVulnerabilityInsightRequest
      >,
    ) {
      state.vulnerabilities = PagedQueryState.next(
        state.vulnerabilities,
        action,
        {
          mapData: r => r?.items,
          mapTotalCount: r => r?.total || 0,
        },
      );
    },
    getEcrImages(
      state,
      action: QueryAction<
        ECRImageVisibilityDashboard,
        DefaultApiGetEcrImageListRequest
      >,
    ) {
      state.vulnerabilities = PagedQueryState.next(
        state.vulnerabilities,
        action,
        {
          mapData: r => r?.items,
          mapTotalCount: r => r?.total || 0,
        },
      );
    },

    getCveVulnerabilities(
      state,
      action: QueryAction<CVEDetailPagination, DefaultApiGetCvesRequest>,
    ) {
      state.cves = PagedQueryState.next(state.cves, action, {
        mapData: r => r.items,
        mapTotalCount: r => r.total ?? 0,
      });
    },

    getPackageVulnerabilities(
      state,
      action: QueryAction<
        VulnerablePackageResponse,
        VulnerabilitiesApiGetPackageVulnerabilitiesRequest
      >,
    ) {
      state.vulnerabilities = PagedQueryState.next(
        state.vulnerabilities,
        action,
        {
          mapData: r => r?.items,
          mapTotalCount: r => r?.total || 0,
        },
      );
    },

    getCveHosts(
      state,
      action: QueryAction<
        InsightV2Pagination,
        VulnerabilitiesApiGetCveResourcesRequest
      >,
    ) {
      state.cveHosts = PagedQueryState.next(state.cveHosts, action, {
        mapData: r => r?.items,
        mapTotalCount: r => r?.total || 0,
      });
    },

    getPackageHosts(
      state,
      action: QueryAction<
        InsightV2Pagination,
        VulnerabilitiesApiGetPackageResourcesRequest
      >,
    ) {
      state.cveHosts = PagedQueryState.next(state.cveHosts, action, {
        mapData: r => r?.items,
        mapTotalCount: r => r?.total || 0,
      });
    },

    getVulnerabilitiesBySeverity(
      state,
      action: QueryAction<any, ChartsApiGetVulnerabilitiesDetailsRequest>,
    ) {
      state.vulnerabilitiesBySeverity = QueryState.next(
        state.vulnerabilitiesBySeverity,
        action,
        {
          mapData: res => {
            const severityData: Record<string, any> = {};
            forEach(res?.critical_resources, (value, date) => {
              severityData[date] = {
                critical: value,
                medium: res?.medium_resources?.[date],
                high: res?.high_resources?.[date],
                low: res?.low_resources?.[date],
              };
            });
            return severityData;
          },
        },
      );
    },

    getVulnerabilitiesBySeverityCounts(
      state,
      action: QueryAction<any, ChartsApiGetVulnerabilitiesDetailsRequest>,
    ) {
      state.vulnerabilitiesBySeverityCounts = QueryState.next(
        state.vulnerabilitiesBySeverityCounts,
        action,
      );
    },

    getHostsWithCriticalHighVulnerabilities(
      state,
      action: QueryAction<any, ChartsApiGetVulnerabilitiesDetailsRequest>,
    ) {
      state.hosts = QueryState.next(state.hosts, action);
    },

    getCveHostsRiskContext(
      state,
      action: QueryAction<
        Record<string, RiskContextResponse[]>,
        ContextApiGetRiskContextResRequest
      >,
    ) {
      state.cveHostsRiskContext = QueryState.next(
        state.cveHostsRiskContext,
        action,
      );
    },

    getCveHostsVulnerabilities(
      state,
      action: QueryAction<
        ResourceVulnerabilities[],
        VulnerabilitiesApiGetVulnerabilitiesRequest
      >,
    ) {
      const resourceType =
        action.payload.q.resourceVulnerabilitiesRequest.native_resource;
      if (!state.cveHostsVulnerabilities[resourceType!]) {
        state.cveHostsVulnerabilities[resourceType!] = QueryState.init(
          {} as any,
          {
            status: QueryStatus.pending,
          },
        );
        return;
      } else {
        state.cveHostsVulnerabilities[resourceType!] = QueryState.next(
          state.cveHostsVulnerabilities[resourceType!],
          action,
          {
            mapData: res => {
              let data: Record<string, any> = {};
              forEach(res, o => (data[o?.resource_id || ''] = o));
              return data;
            },
          },
        );
      }
    },

    /*get data scanner info*/
    getScannerInfo(state, action: QueryAction<Record<string, ScannerInfo[]>>) {
      state.scannerInfo = QueryState.next(state.scannerInfo, action);
    },

    getCVEDetails(
      state,
      action: QueryAction<CVEDetailPagination, DefaultApiGetCvesByIdRequest>,
    ) {
      state.cveDetails = QueryState.next(state.cveDetails, action, {
        mapData: r => r?.items?.[0],
      });
    },

    getVulnerabilityPrioritizationData(
      state,
      action: QueryAction<
        VulnerabilityPriortization,
        DefaultApiGetVulnerabilityPriortizationDataRequest
      >,
    ) {
      state.vulnerabilityPrioritization = QueryState.next(
        state.vulnerabilityPrioritization,
        action,
      );
    },

    getVulnerabilitiesBySources(
      state,
      action: QueryAction<
        BySourcesDashboard,
        DefaultApiGetBySourcesDashboardDataRequest
      >,
    ) {
      state.vulnerabilitiesBySources = QueryState.next(
        state.vulnerabilitiesBySources,
        action,
      );
    },

    getMostDeployedVulnerableImages(
      state,
      action: QueryAction<
        SunburstDashboard,
        DashboardApiGetResourcesByImagesForDashboardRequest
      >,
    ) {
      state.vulnerableImages = QueryState.next(state.vulnerableImages, action);
    },

    updateSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
    },

    updateSelectedTab(state, action: PayloadAction<SideNavKey>) {
      state.selectedTab = action.payload;
    },

    updateCveTabIndex(state, action: PayloadAction<number>) {
      state.tablesTabIndex = action.payload;
    },

    updateSortInfo(state, action: PayloadAction<Sorted>) {
      state.sort = action.payload;
    },

    updateVulnerabilityPageInfo(state, action: PayloadAction<PageInfo>) {
      state.vulnerabilities.page.info = action.payload;
    },

    updateCvePageInfo(state, action: PayloadAction<PageInfo>) {
      state.cves.page.info = action.payload;
    },

    updateCveHostsPageInfo(state, action: PayloadAction<PageInfo>) {
      state.cveHosts.page.info = action.payload;
    },

    updatePublishedAt(state, action: PayloadAction<Record<string, any>>) {
      state.publishedAt = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = vulnerabilitySlice;
