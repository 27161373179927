import React, { FC } from 'react';

import { AggregatedAlertDetails } from '@ariksa/notification/api';
import {
  Box,
  Divider,
  HStack,
  Stack,
  Text,
  GridItem,
  Grid,
} from '@chakra-ui/react';
import { entries, map, values } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dict } from 'types/utils';

import { DetailsIdentifier, Heading4 } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { LinkText } from 'components/DataDisplay/Text/LinkText';
import { renderCompliance } from 'components/DataDisplay/Utils/renderCompliance';
import { renderAlert } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/utils';
import { EnvironmentName } from 'containers/App/utils';
import { selectAlertDrawer } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/selectors';
import { AlertStatus } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertStatus';
import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';

import { IndividualAlertTable } from './IndividualAlertTable';

interface IAggregatedAlertDrawerContent {
  alert: AggregatedAlertDetails;
  tabIndex: number;
  setTabIndex: (value: number) => void;
}

export const AlertDrawerContent: FC<IAggregatedAlertDrawerContent> = props => {
  const { alert } = props;
  const navigate = useNavigate();

  const { compliance } = useSelector(selectFindingsOverview);
  const { alertListStatus } = useSelector(selectAlertDrawer);

  const renderAlertStatus = () => {
    const tickets = values(alertListStatus.data);
    if (!tickets.length) {
      return <Text>-</Text>;
    }
    const status: Dict<number> = {};
    tickets.forEach(ts => {
      ts.forEach(t => {
        const ticketStatus = t.ticket_status ?? 'todo';
        status[ticketStatus] = status[ticketStatus] ?? 0;
        status[ticketStatus]++;
      });
    });

    return (
      <HStack>
        {entries(status).map(([k, v]) => {
          return <AlertStatus status={k} count={v} />;
        })}
      </HStack>
    );
  };

  return (
    <Stack w="full" h="full" spacing={3}>
      <Stack w="full" spacing={4}></Stack>
      <Grid templateColumns="repeat(3, 1fr)" gap={4}>
        <GridItem colSpan={1}>
          <Stack>
            <Heading4>Severities</Heading4>
            <HStack spacing={4}>
              <HStack spacing={4}>
                {alert.severity?.CRITICAL &&
                  renderAlert(alert.severity?.CRITICAL, 'critical')}
                {alert.severity?.HIGH &&
                  renderAlert(alert.severity?.HIGH, 'high')}
                {alert.severity?.MEDIUM &&
                  renderAlert(alert.severity?.MEDIUM, 'medium')}
                {alert.severity?.LOW && renderAlert(alert.severity?.LOW, 'low')}
              </HStack>
            </HStack>
          </Stack>
        </GridItem>
        <GridItem colSpan={1}>
          <Stack>
            <Heading4>Environment</Heading4>
            <HStack spacing={4}>
              <EnvironmentName environmentId={alert.environment_id ?? ''} />
            </HStack>
          </Stack>
        </GridItem>
        <GridItem colSpan={1}>
          <Stack>
            <Heading4>Accounts</Heading4>
            <HStack spacing={4}>
              <Text>{alert.account_id_list?.length ?? 0}</Text>
            </HStack>
          </Stack>
        </GridItem>
        <GridItem colSpan={1}>
          <Stack spacing={3}>
            <Heading4>Policy</Heading4>
            <Stack spacing={1}>
              {map(alert?.blueprints, o => (
                <>
                  <LinkText
                    onClick={() =>
                      navigate(`/policy-hub/blueprint/${o.blueprint_id}`)
                    }
                    fontSize="sm"
                  >
                    {o.blueprint_name}
                  </LinkText>
                </>
              ))}
            </Stack>
          </Stack>
        </GridItem>
        <GridItem colSpan={1}>
          <Stack spacing={3}>
            <Heading4>Compliance</Heading4>
            {compliance.isLoading ? (
              <Box w={'30px'}>
                <CustomSpinner />
              </Box>
            ) : (
              renderCompliance(
                compliance.data?.[alert.alert_rule_ids?.[0]!] ?? [],
              )
            )}
          </Stack>
        </GridItem>
        <GridItem colSpan={1}>
          <Stack spacing={3}>
            <Heading4>Status</Heading4>
            {alertListStatus.isLoading ? (
              <Box w={'30px'}>
                <CustomSpinner />
              </Box>
            ) : (
              renderAlertStatus()
            )}
          </Stack>
        </GridItem>
      </Grid>

      <Divider color="gray.200" />

      <Stack h={'700px'}>
        <IndividualAlertTable aggregatedAlert={alert} />
      </Stack>
    </Stack>
  );
};
