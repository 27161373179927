import React, { FC, useCallback, useEffect } from 'react';

import { ConnectionRelationship } from '@ariksa/inventory-core/api';
import { useDispatch, useSelector } from 'react-redux';
import { INIT_PAGE_INFO } from 'services/utils/constants';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';
import { SubComponentContainer } from 'containers/Compliance/Components/ComplianceTables/SubComponentContainer';

interface Props {
  processUuid: string;
}

export const ProcessConnections: FC<Props> = ({ processUuid }) => {
  const { processConnections } = useSelector(selectActiveResource);
  const dispatch = useDispatch();
  const getProcessConnections = useCallback(
    (pageInfo = INIT_PAGE_INFO) => {
      dispatch(
        actions.getProcessConnections({
          q: {
            processUuid: processUuid,
            relationship: ConnectionRelationship.InteractWith,
          },
          page: pageInfo,
        }),
      );
    },
    [dispatch, processUuid],
  );

  useEffect(() => {
    getProcessConnections();
  }, [getProcessConnections]);

  const subColumns = [
    {
      header: 'Source IP',
      accessor: 'src_ip_addr',
    },
    {
      header: 'Source Port',
      accessor: 'src_port',
    },
    {
      header: 'Destination IP',
      accessor: 'dst_ip_addr',
    },
    {
      header: 'Destination Port',
      accessor: 'dst_port',
    },
    {
      header: 'Protocol',
      accessor: 'protocol',
    },
    {
      header: 'Encrypted',
      render: (row: any) => (row.encrypted ? 'Yes' : 'No'),
    },
  ];

  return (
    <SubComponentContainer>
      <Table
        columns={subColumns}
        data={processConnections[processUuid]?.data ?? []}
        isLoading={processConnections[processUuid]?.isLoading}
      />
    </SubComponentContainer>
  );
};
