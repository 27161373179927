import React from 'react';

import { Icon } from '@chakra-ui/react';

export const OptionsIcon = props => (
  <Icon viewBox="0 0 720 720" h="full" w="full" color={'inherit'} {...props}>
    <g transform="translate(-280 0)">
      <g>
        <path
          d="M640 510C673.137 510 700 536.863 700 570 700 603.137 673.137 630 640 630 606.863 630 580 603.137 580 570 580 536.863 606.863 510 640 510Z"
          stroke="currentColor"
          stroke-width="45"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          stroke-miterlimit="4"
          stroke-opacity="1"
          fill="none"
          fill-rule="nonzero"
        />
        <path
          d="M640 300C673.137 300 700 326.863 700 360 700 393.137 673.137 420 640 420 606.863 420 580 393.137 580 360 580 326.863 606.863 300 640 300Z"
          stroke="currentColor"
          stroke-width="45"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          stroke-miterlimit="4"
          stroke-opacity="1"
          fill="none"
          fill-rule="nonzero"
        />
        <path
          d="M640 90C673.137 90 700 116.863 700 150 700 183.137 673.137 210 640 210 606.863 210 580 183.137 580 150 580 116.863 606.863 90 640 90Z"
          stroke="currentColor"
          stroke-width="45"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          stroke-miterlimit="4"
          stroke-opacity="1"
          fill="none"
          fill-rule="nonzero"
        />
      </g>
    </g>
  </Icon>
);
