/**
 *
 * Jit Requests
 *
 */
import React, { useCallback, useEffect } from 'react';

import { Box, HStack, Stack } from '@chakra-ui/react';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { INIT_PAGE_INFO } from 'services/utils/constants';

import { Card, PageHeaderWithIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { AddButton, Select } from 'components/DataEntry';
import { DataScanIcon, PermissionsActionsIcon } from 'components/Icons';
import { usePageContentContext } from 'components/Layout';
import { Menu } from 'components/Overlay';
import {
  outpostStatusOptions,
  outpostTypeOptions,
} from 'containers/Setup/Outpost/Components/utils';
import { JitRequestsTable } from 'containers/Visibility/JitRequests/Components/JitRequestsTable';
import { selectJitRequests } from 'containers/Visibility/JitRequests/selectors';
import { useInjector } from 'utils/inject';

import { jitRequestsSaga } from './saga';
import { actions, reducer, sliceKey } from './slice';

export const JitRequests = () => {
  useInjector(sliceKey, reducer, jitRequestsSaga);
  const { contentHeight } = usePageContentContext();
  const dispatch = useDispatch();
  const { jitRequests } = useSelector(selectJitRequests);

  /*const getJitApproveRequests = useCallback(
    (page = INIT_PAGE_INFO) => {
      dispatch(actions.getJitRequests({ q: {} }));
    },
    [dispatch, ],
  );*/

  useEffect(() => {
    dispatch(
      actions.getJitRequests({
        q: {
          page: jitRequests.page.info.page_number,
          size: jitRequests.page.info.page_size,
        },
      }),
    );
  }, [dispatch, jitRequests.page.info]);

  return (
    <Card styles={{ card: { h: contentHeight + 'px' } }}>
      <Stack h="full" spacing={5} w="full">
        <HStack w="full" justify="space-between">
          <PageHeaderWithIcon
            label="JIT Requests"
            icon={<PermissionsActionsIcon />}
          />
          {/*<HStack>
          <Box w={48} zIndex={900}>
            <Select
              options={outpostStatusOptions}
              value={state.status}
              onChange={s => updateState({ status: s })}
              showIconInValueContainer
              isDisabled={
                vulnerabilityOutposts.isLoading ||
                dataOutposts.isLoading ||
                resourceDetails.isLoading
              }
              //styles={styles}
            />
          </Box>
          <Box w={48} zIndex={900}>
            <Select
              options={state.regionOptions}
              value={state.region}
              onChange={s => updateState({ region: s })}
              showIconInValueContainer
              //styles={styles}
              isDisabled={
                vulnerabilityOutposts.isLoading ||
                dataOutposts.isLoading ||
                resourceDetails.isLoading
              }
            />
          </Box>
          <Box w={48} zIndex={900}>
            <Select
              options={outpostTypeOptions}
              value={state.outpostType}
              onChange={s => updateState({ outpostType: s })}
              showIconInValueContainer
              //styles={styles}
              isDisabled={
                vulnerabilityOutposts.isLoading ||
                dataOutposts.isLoading ||
                resourceDetails.isLoading
              }
            />
          </Box>
          <Box w={48} zIndex={900}>
            <Select
              options={state.accountOptions}
              value={state.account}
              onChange={s => updateState({ account: s })}
              showIconInValueContainer
              styles={{
                menu: provided => ({
                  ...provided,
                  width: 'max-content',
                  minWidth: '100%',
                  right: 0,
                }),
              }}
              isDisabled={
                vulnerabilityOutposts.isLoading ||
                dataOutposts.isLoading ||
                resourceDetails.isLoading
              }
            />
          </Box>
        </HStack>*/}
        </HStack>
        <JitRequestsTable />
      </Stack>
    </Card>
  );
};
