import React, { FC } from 'react';

import { Box, Center, HStack, Stack, Text } from '@chakra-ui/react';
import { map } from 'lodash';
import { colorOpacity, customTheme } from 'theme';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';

interface FunnelChartProps {
  data: Record<string, any>[];
  isLoading?: boolean;
  colors?: string[];
  height?: number[];
  labelKey: string;
  dataKey: string;
}

const FunnelChart: FC<FunnelChartProps> = props => {
  const {
    data,
    isLoading,
    colors,
    height = [100, 58, 34, 20, 12],
    dataKey,
    labelKey,
  } = props;
  const defaultColor = [
    colorOpacity(customTheme.colors.primary, 0.2),
    'orange.200',
    'yellow.100',
    'pink.100',
    'blue.200',
  ];
  const renderTrapezoid = (count, height, color) => {
    return (
      <Box
        position="relative"
        width="full"
        height={height + '%'}
        clipPath="polygon(0% 0%, 100% 20%, 100% 80%, 0% 100%)"
        bg={color}
        display="flex"
        justifyContent="center"
        alignItems="center"
        fontSize="lg"
      >
        <Text
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          fontWeight="bold"
          color="black"
        >
          {formatNumber(count)}
        </Text>
      </Box>
    );
  };
  return (
    <Center w="full" h="full">
      {isLoading ? (
        <CustomSpinner />
      ) : (
        <Stack w="full" h="full" justify="space-between">
          <HStack w="full" h="80%" spacing={1}>
            {map(data, (o, index) =>
              renderTrapezoid(
                o?.[dataKey],
                height[index],
                colors?.[index] ?? defaultColor[index],
              ),
            )}
          </HStack>
          <HStack w="full" h="10%" spacing={1} fontSize="15px">
            {map(data, o => (
              <Center w="full" textAlign="center">
                {o?.[labelKey]}
              </Center>
            ))}
          </HStack>
        </Stack>
      )}
    </Center>
  );
};

export default FunnelChart;
