import { AuditTrailServiceApiAuditTrailServiceResourceActionsMonthlyRequest } from '@ariksa/audittrail/api';
import {
  CloudAccountApiGetCloudAccountsRequest,
  CloudAccountApiOnboardJitAccountRequest,
  CloudAccountApiReadConfigRequest,
  OnboardingConfigResponse,
  PageCloudAccountGetResponse,
} from '@ariksa/cloud-account/api';
import {
  ItemApiApproveRequestRequest,
  ItemApiListAllRequestsRequest,
  PageAccessRequestGetResponse,
} from '@ariksa/jit';
import {
  CloudAccountDeleteResponse,
  ItemApiDeleteAccountRequest,
  ItemApiDenyRequestRequest,
  ItemApiEditApproversRequest,
  ItemApiGetAssumeRolePolicyRequest,
  ItemApiGetJitStatusRolesRequest,
  ItemApiListAccountsRequest,
  ItemApiToggleAutoApproveRequest,
  ItemApiUpdateJitRolesRequest,
  JitEnabledStatusGetResponse,
  PageCloudAccountInfoGetResponse,
  ResponseDenyRequestV1JitRequestsDenyRequestIdPost,
  ResponseUpdateJitRolesV1JitAccountJitRoleUpdateAccountIdPost,
  ToggleAutoApproveResponse,
} from '@ariksa/jit/api';
import { QueryAction } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';

import {
  UserServiceListRequest,
  UserServiceListResponse,
} from 'api/auth/api.pb';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Settings container
export const initialState: ContainerState = {
  accounts: PagedQueryState.init([]),

  jitAction: QueryState.init({}),
  addJitApprover: QueryState.init({}),
  editJitApprover: QueryState.init({}),
  jitAccounts: PagedQueryState.init([]),
  jitApproverList: QueryState.init([]),
  deleteJitApprover: QueryState.init(null),

  jitApproveRequests: PagedQueryState.init([]),
  approveJitAccount: QueryState.init(null),
  denyJitAccount: QueryState.init(null),
  policyDocument: QueryState.init(null),
  onboardingConfig: QueryState.init({} as any),
  jitUsers: QueryState.init({} as any),
  resourceActionPerHour: QueryState.init({}),
  resourceActionOnDay: QueryState.init({}),
  roles: QueryState.init({} as any),
  updateJitRoles: QueryState.init({} as any),
  updateAutoApprovals: QueryState.init({} as any),
};

const settingsSlice = createSlice({
  name: 'jit',
  initialState,
  reducers: {
    getResourceActionPerHour(
      state,
      action: QueryAction<
        any,
        AuditTrailServiceApiAuditTrailServiceResourceActionsMonthlyRequest
      >,
    ) {
      state.resourceActionPerHour = QueryState.next(
        state.resourceActionPerHour,
        action,
      );
    },

    getResourceActionOnDay(state, action: QueryAction<any, any>) {
      state.resourceActionOnDay = QueryState.next(
        state.resourceActionOnDay,
        action,
      );
    },
    getCloudAccounts(
      state,
      action: QueryAction<
        PageCloudAccountGetResponse,
        CloudAccountApiGetCloudAccountsRequest
      >,
    ) {
      state.accounts = PagedQueryState.next(state.accounts, action, {
        mapTotalCount: r => r.total || 0,
        mapData: r => r.items,
      });
    },

    addJitAccount(
      state,
      action: QueryAction<any, CloudAccountApiOnboardJitAccountRequest>,
    ) {
      state.jitAction = QueryState.next(state.jitAction, action);
    },

    addJitApprover(
      state,
      action: QueryAction<string, ItemApiEditApproversRequest>,
    ) {
      state.addJitApprover = QueryState.next(state.addJitApprover, action);
    },
    editJitApprover(
      state,
      action: QueryAction<any, ItemApiEditApproversRequest>,
    ) {
      state.editJitApprover = QueryState.next(state.editJitApprover, action);
    },
    getJitAccounts(
      state,
      action: QueryAction<
        PageCloudAccountInfoGetResponse,
        ItemApiListAccountsRequest
      >,
    ) {
      state.jitAccounts = PagedQueryState.next(state.jitAccounts, action, {
        mapTotalCount: r => r.total || 0,
        mapData: r => r.items,
      });
    },
    getJitApproverList(
      state,
      action: QueryAction<UserServiceListResponse, UserServiceListRequest>,
    ) {
      state.jitApproverList = QueryState.next(state.jitApproverList, action, {
        mapData: r => r.users ?? [],
      });
    },
    deleteJitAccount(
      state,
      action: QueryAction<
        CloudAccountDeleteResponse,
        ItemApiDeleteAccountRequest
      >,
    ) {
      state.deleteJitApprover = QueryState.next(
        state.deleteJitApprover,
        action,
      );
    },

    getJitApproveRequests(
      state,
      action: QueryAction<
        PageAccessRequestGetResponse,
        ItemApiListAllRequestsRequest
      >,
    ) {
      state.jitApproveRequests = PagedQueryState.next(
        state.jitApproveRequests,
        action,
        {
          mapTotalCount: r => r.total || 0,
          mapData: r => r.items,
        },
      );
    },
    approveJitAccount(
      state,
      action: QueryAction<any, ItemApiApproveRequestRequest>,
    ) {
      state.approveJitAccount = QueryState.next(
        state.approveJitAccount,
        action,
      );
    },
    denyJitAccount(
      state,
      action: QueryAction<
        ResponseDenyRequestV1JitRequestsDenyRequestIdPost,
        ItemApiDenyRequestRequest
      >,
    ) {
      state.denyJitAccount = QueryState.next(state.denyJitAccount, action);
    },
    getPolicyDocument(
      state,
      action: QueryAction<any, ItemApiGetAssumeRolePolicyRequest>,
    ) {
      state.policyDocument = QueryState.next(state.policyDocument, action);
    },
    getOnboardingConfig(
      state,
      action: QueryAction<
        OnboardingConfigResponse,
        CloudAccountApiReadConfigRequest
      >,
    ) {
      state.onboardingConfig = QueryState.next(state.onboardingConfig, action);
    },
    getJitUsers(
      state,
      action: QueryAction<UserServiceListResponse, UserServiceListRequest>,
    ) {
      state.jitUsers = QueryState.next(state.jitUsers, action);
    },
    getRoles(
      state,
      action: QueryAction<
        JitEnabledStatusGetResponse,
        ItemApiGetJitStatusRolesRequest
      >,
    ) {
      state.roles = QueryState.next(state.roles, action);
    },
    updateJitRoles(
      state,
      action: QueryAction<
        ResponseUpdateJitRolesV1JitAccountJitRoleUpdateAccountIdPost,
        ItemApiUpdateJitRolesRequest
      >,
    ) {
      state.updateJitRoles = QueryState.next(state.updateJitRoles, action);
    },
    updateAutoApprovals(
      state,
      action: QueryAction<
        ToggleAutoApproveResponse,
        ItemApiToggleAutoApproveRequest
      >,
    ) {
      state.updateAutoApprovals = QueryState.next(
        state.updateAutoApprovals,
        action,
      );
    },
  },
});

export const { actions, reducer, name: sliceKey } = settingsSlice;
