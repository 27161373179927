import React, { useCallback, useEffect } from 'react';

import { PackageCategory } from '@ariksa/inventory-core';
import { ConnectionRelationship } from '@ariksa/inventory-core/api';
import {
  Accordion,
  Box,
  HStack,
  Stack,
  Square,
  Center,
  Text,
} from '@chakra-ui/react';
import { dispatch } from 'd3';
import { entries, isObject } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { INIT_PAGE_INFO } from 'services/utils/constants';

import { renderContextTags } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { Select } from 'components/DataEntry';
import {
  IconTypes,
  LearnMoreIcon,
  MaliciousIngressIcon,
  UnencryptedDiskIcon,
} from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { StorageEncrypted } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/MetaGridFields';
import { ProcessConnections } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Compute/VirtualMachine/ProcessConnections';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';
import { selectActiveEnvironment } from 'containers/App/selectors';
import { SubComponentContainer } from 'containers/Compliance/Components/ComplianceTables/SubComponentContainer';

const styles = {
  menu: provided => ({
    ...provided,
    width: 'max-content',
    minWidth: '100%',
    right: 0,
  }),
};

export const Ec2AppsTab = () => {
  const dispatch = useDispatch();
  const { environmentId } = useSelector(selectActiveEnvironment);
  const { resourceUuid } = useActiveResourceContext();
  const { runningProcesses, processConnections } = useSelector(
    selectActiveResource,
  );

  const columns = [
    {
      header: 'Name',
      accessor: 'name',
    },
    {
      header: 'Type',
      accessor: 'connection_type',
    },
    {
      header: 'System',
      render: ({ row }) => {
        return row.system_process ? 'Yes' : 'No';
      },
    },
    {
      header: 'External Access',
      render: ({ row }) => {
        return (
          <HStack>
            <Text>{row.external_access ? 'Yes' : 'No'}</Text>
          </HStack>
        );
      },
    },
    {
      header: 'Data Access',
      render: ({ row }) => {
        return row.data_access ? 'Yes' : 'No';
      },
    },
    {
      header: 'Context',
      render: ({ row }) => {
        return (
          <Center>
            {row.context.map(ctx => {
              if (isObject(ctx)) {
                if (ctx?.['encrypted'] === 'False') {
                  return (
                    <Square size={6}>
                      <UnencryptedDiskIcon />
                    </Square>
                  );
                }

                if (ctx?.['malicious'] === 'True') {
                  return (
                    <Square size={6}>
                      <MaliciousIngressIcon />
                    </Square>
                  );
                }
              }
              return '-';
            })}
          </Center>
        );
      },
    },
  ];

  const getRunningProcess = useCallback(
    (pageInfo = INIT_PAGE_INFO) => {
      console.log('running process');
      dispatch(
        actions.getRunningProcesses({
          q: {
            environmentId,
            resourceUuid,
          },
          page: pageInfo,
        }),
      );
    },
    [dispatch, environmentId, resourceUuid],
  );

  useEffect(() => {
    getRunningProcess();
  }, [getRunningProcess]);

  return (
    <Stack w={'full'} h={'full'} pb={5} spacing={8}>
      <Box>
        <Accordion allowMultiple defaultIndex={[0]}>
          <MetaAccordionItem
            iconType={IconTypes.Credential}
            label={'SHOWING APPS FOR NODE'}
            onClickDownload={() => {}}
            panel={
              <Stack w={'full'} h={'full'} spacing={4}>
                <HStack justify={'end'} w={'full'}>
                  <Box w={56} zIndex={900}>
                    <Select
                      options={[
                        {
                          label: 'All Types',
                          value: '',
                        },
                        {
                          label: 'OS',
                          value: PackageCategory.OsPackage,
                        },
                        {
                          label: 'Library',
                          value: PackageCategory.LibraryPackage,
                        },
                        {
                          label: 'Technology',
                          value: PackageCategory.ApplicationPackage,
                        },
                      ]}
                      // value={filters.type ?? { label: 'All Types', value: '' }}
                      // onChange={s => updateFilter('type', s)}
                      showIconInValueContainer
                      styles={styles}
                      menuPortalTarget={document.body}
                    />
                  </Box>
                </HStack>
                <Box flex={1}>
                  <Table
                    isLoading={runningProcesses.isLoading}
                    columns={columns}
                    data={runningProcesses.data ?? []}
                    subComponent={({ row, expanded }) => {
                      return (
                        <>
                          {expanded && (
                            <ProcessConnections processUuid={row.uuid} />
                          )}
                        </>
                      );
                    }}
                  />
                </Box>
              </Stack>
            }
            totalCount={runningProcesses.data.totalCount}
            iconBGColor="green"
          />
        </Accordion>
      </Box>
    </Stack>
  );
};
