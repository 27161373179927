import React from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { colorOpacity, customTheme } from 'theme';

import { formatTooltip } from 'components/DataDisplay';
import { IconTypes, AutomationIcon } from 'components/Icons';
import { selectDashboardOverview } from 'containers/Dashboard/Overview/selectors';
import { IdentityCards } from 'containers/Dashboard/utils/IdentityCards';

export const PolicyManagementAndAutomation: React.FC = () => {
  const { stateOfYourCloud } = useSelector(selectDashboardOverview);
  const { policyManagement, workflows } = stateOfYourCloud;
  const navigate = useNavigate();

  return (
    <IdentityCards
      label="Policy management and automation"
      icon={<AutomationIcon />}
      cardH="11rem"
      cardW="9rem"
      iconBoxSize={10}
      labelFontSize="md"
      contentSpacing={4}
      countFontSize="xl"
      items={[
        {
          label: 'Blueprints',
          count: policyManagement.data?.blueprints || 0,
          isLoading: policyManagement.isLoading,
          iconType: IconTypes.Policy,
          onClick: () => navigate('policy-hub/blueprint'),
          iconBgColor: colorOpacity(customTheme.colors.skyBlue['300'], 0.2),
          borderColor: 'skyBlue.300',
          iconPadding: 2,
          iconTooltip: formatTooltip({
            header: 'Blueprints',
            content:
              'Policy blueprints that are used to track policy violations across one or more environments',
            width: 48,
          }),
        },
        {
          label: 'Total Policies',
          count: policyManagement.data?.policies || 0,
          isLoading: policyManagement.isLoading,
          iconType: IconTypes.List,
          iconBgColor: colorOpacity(customTheme.colors.blue['300'], 0.2),
          borderColor: 'blue.300',
          onClick: () => navigate('policy-hub/policy'),
          iconTooltip: formatTooltip({
            header: 'Enforced policies',
            content:
              'Total unique policies that are being enforced in your environment',
            width: 48,
          }),
        },
        {
          label: 'Exceptions',
          count: policyManagement.data?.exceptions,
          isLoading: policyManagement.isLoading,
          iconType: IconTypes.PolicyException,
          iconBgColor: colorOpacity(customTheme.colors.green['300'], 0.2),
          borderColor: 'green.300',
          iconPadding: 2,
          onClick: () => navigate('policy-hub/blueprint'),
          iconTooltip: formatTooltip({
            header: 'Exceptions',
            content:
              'Policy exceptions that have been granted across all blueprints',
            width: 48,
          }),
        },
        {
          label: 'Workflows',
          count: workflows.data || 0,
          isLoading: workflows.isLoading,
          iconType: IconTypes.Automation,
          iconBgColor: colorOpacity(customTheme.colors.primary, 0.2),
          borderColor: 'orange',
          iconPadding: 2,
          onClick: () => navigate('policy-hub/workflow'),
          iconTooltip: formatTooltip({
            header: 'Workflows',
            content: 'Active workflows for automatic issue routing',
            width: 48,
          }),
        },
      ]}
    />
  );
};
