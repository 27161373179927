import React, { FC } from 'react';

import {
  Box,
  Center,
  HStack,
  Link,
  ListItem,
  OrderedList,
  Stack,
  Text,
} from '@chakra-ui/react';
import jsonBeautify from 'json-beautify';
import { customTheme } from 'theme';

import { AriksaIcon, H3, SyntaxHighlight } from 'components/DataDisplay';
import { CopyToClipboardIconButton } from 'components/DataEntry';
import { IconTypes } from 'components/Icons';
import { AwsConsoleSteps } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/AwsConsoleSteps';

interface IRemediationCommand {
  type: string;
  steps?: string[];
  command?: string;
  console_path?: string;
  aws_console_steps?: any[];
  document?: string;
  note?: string;
}
// @ts-ignore
const json = val => jsonBeautify(val || {}, null, 2, 10);

export const RemediationCommand: FC<IRemediationCommand> = props => {
  const {
    type,
    steps = [],
    command,
    console_path = '',
    aws_console_steps = [],
    document,
    note,
  } = props;

  return (
    <Stack px={4} py={3} spacing={4}>
      <HStack spacing={4}>
        <Center color={'primary'}>
          <AriksaIcon
            type={IconTypes.CommandLine}
            size={'regular'}
            bg={'primary'}
          />
        </Center>
        <H3>{type}</H3>
      </HStack>
      <Stack pl={10}>
        {!!steps.length && (
          <Box w={'full'} py={4}>
            <OrderedList spacing={2}>
              {steps.map(el => (
                <ListItem>
                  <Text>{el}</Text>
                </ListItem>
              ))}
            </OrderedList>
          </Box>
        )}

        {command && (
          <HStack>
            <Box
              w="full"
              border="1px solid"
              borderColor={customTheme.colors.gray['100']}
              px={3}
              py={2}
              bg="white"
              color="primary"
              borderRadius={6}
            >
              {command}
            </Box>
            <Box>
              <CopyToClipboardIconButton value={command} size="xs" />
            </Box>
          </HStack>
        )}
        {document && (
          <Stack spacing={4} pt={4}>
            <H3>Policy Document</H3>
            <Box p={2} bg={'#fff'}>
              <SyntaxHighlight language={'json'}>
                {json(JSON.parse(document))}
              </SyntaxHighlight>
            </Box>
          </Stack>
        )}
        {note && (
          <Stack spacing={4} pt={4}>
            <H3>Note</H3>
            <Box>
              <Text>{note ?? '-'}</Text>
            </Box>
          </Stack>
        )}

        <Stack spacing={8}>
          {aws_console_steps && <AwsConsoleSteps steps={aws_console_steps} />}
          {console_path && (
            <Box>
              <Text>Follow the link for more details</Text>
              <Link
                target="_blank"
                href={console_path.toString()}
                color={'primary'}
              >
                {console_path}
              </Link>
            </Box>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
