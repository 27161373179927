import React, { FC, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { NewUserParams, UserServiceUpdateRequest } from 'api/auth/api.pb';
import { UserId } from 'api/auth/types';
import { Card, Layout } from 'app/components';
import { useKeycloakContext } from 'containers/App/keycloak/context';
import { selectUser } from 'containers/App/selectors';
import { useSearchParams } from 'hooks/useSearchParams';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { setupUsersSaga } from '../saga';
import { selectSetupUsers } from '../selectors';
import { actions, reducer, sliceKey } from '../slice';

import { UserDetails } from './UserDetails';

export const EditUser: FC = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: setupUsersSaga });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userForm, updateUser, activeUser } = useSelector(selectSetupUsers);
  const params = useSearchParams<UserId>();
  const { info } = useSelector(selectUser);
  const { forceRefreshToken } = useKeycloakContext();

  useEffect(() => {
    if (params.user_id) {
      dispatch(
        actions.getUserById({ id: params.user_id, organization: info.org_id }),
      );
    }
  }, [dispatch, info.org_id, params]);

  const handleSubmit = data => {
    const {
      roles = [],
      user_groups = [],
      fullname,
      email,
      account_ids = [],
      auto_add_accounts,
    } = data;

    const { first_name, last_name } = fullname;

    const user: UserServiceUpdateRequest = {
      user: {
        ...activeUser,
        first_name,
        last_name,
        email,
        roles: roles?.map(r => r.value),
        user_groups: user_groups?.map(g => g.value),
        account_ids: account_ids?.map(r => r?.value) || [],
        auto_add_accounts,
      },
    };

    //update user form
    dispatch(actions.updateUserForm(user.user as NewUserParams));

    dispatch(
      actions.updateUser({
        q: user,
        onSuccess: () => {
          if (activeUser.id === info.id) {
            forceRefreshToken();
          }
          navigate('/setup/users');
        },
      }),
    );
  };

  return (
    <Layout
      middleComponent={
        <Card
          styles={{ card: { pl: 32 } }}
          contentComponent={
            <UserDetails
              isDataLoading={userForm.loading}
              action={'update'}
              isActionOnProgress={updateUser.isLoading}
              onSubmit={handleSubmit}
            />
          }
        />
      }
    />
  );
};
