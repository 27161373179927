import React, { useEffect, useMemo, useState } from 'react';

import { CloudProvider } from '@ariksa/inventory-core';
import { ItemApiApproveRequestRequest } from '@ariksa/jit';
import {
  AccessRequestGetResponse,
  ItemApiDenyRequestRequest,
} from '@ariksa/jit/api';
import { HStack, Square, Stack, Text, theme } from '@chakra-ui/react';
import { ca } from 'date-fns/locale';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { Heading4 } from 'components/DataDisplay';
import { FieldValue } from 'components/DataDisplay/Text/FieldValueText';
import { Form } from 'components/DataEntry';
import { CustomDayPicker } from 'components/DataEntry/Date';
import { TimePicker } from 'components/DataEntry/Time/TimePicker';
import { ManualIcon } from 'components/Icons/ReactResourceIcons/ManualIcon';
import { Modal } from 'components/Overlay';
import { successToast } from 'components/Toast';
import { selectUser } from 'containers/App/selectors';
import { selectJit } from 'containers/Setup/Jit/selectors';
import { actions } from 'containers/Setup/Jit/slice';
import { getCloudUser, JitApprovalStatus } from 'containers/Setup/Jit/utils';
import { parseUtcDate } from 'utils/date';

interface ApproveDenyModalProps {
  request: AccessRequestGetResponse;
  isOpen: boolean;

  onClose(): void;
}

export const ApproveDenyModal = (props: ApproveDenyModalProps) => {
  const { request, isOpen, onClose } = props;
  const { info } = useSelector(selectUser);
  const { denyJitAccount, approveJitAccount } = useSelector(selectJit);
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [startTime, setStartTime] = useState<string>('00:00');
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<string>('00:00');

  const processed =
    request.status === 'APPROVED' || request.status === 'DENIED';

  const account =
    request.access_request_details?.[request.cloud_type.toLowerCase()];

  const requestAccount = useMemo(() => {
    return request.access_request_details?.[request.cloud_type.toLowerCase()];
  }, [request]);

  // parse the request start and end time to local state as dayjs object
  useEffect(() => {
    let requestStartTime: any = null;
    let requestEndTime: any = null;
    if (request.status === 'APPROVED') {
      requestStartTime = parseUtcDate(request.approved_start_time ?? '');
      requestEndTime = parseUtcDate(request.approved_end_time ?? '');
    } else {
      requestStartTime = parseUtcDate(request.requested_start_time);
      requestEndTime = parseUtcDate(request.requested_end_time);
    }

    setStartDate(requestStartTime?.startOf('day')!);
    setEndDate(requestEndTime?.startOf('day')!);

    if (requestStartTime) {
      setStartTime(
        `${requestStartTime.format('HH')}:${requestStartTime.format('mm')}`,
      );
    }

    if (requestEndTime) {
      setEndTime(
        `${requestEndTime.format('HH')}:${requestEndTime.format('mm')}`,
      );
    }
  }, [
    request.approved_end_time,
    request.approved_start_time,
    request.requested_end_time,
    request.requested_start_time,
    request.status,
  ]);

  // combine the date and time to form the start date time object
  const startDateTime = useMemo(() => {
    if (!startDate) return null;
    const [hour, minute] = startTime.split(':');
    let dateTime = startDate.clone();
    dateTime = dateTime.add(Number(hour), 'hour');
    dateTime = dateTime.add(Number(minute), 'minute');

    return dateTime;
  }, [startDate, startTime]);

  // combine the date and time to form the end date time object
  const endDateTime = useMemo(() => {
    if (!endDate) return null;
    const [hour, minute] = endTime.split(':');
    let dateTime = endDate.clone();
    dateTime = dateTime.add(Number(hour), 'hour');
    dateTime = dateTime.add(Number(minute), 'minute');

    return dateTime;
  }, [endDate, endTime]);

  const handleApprove = () => {
    console.log('approving');
    if (!startDateTime || !endDateTime) {
      return;
    }
    const payload: ItemApiApproveRequestRequest = {
      requestId: request.request_id,
      approvalGrantedInfo: {
        approver: info.email ?? '',
        approver_comments: comment,
        cloud_type: request.cloud_type as CloudProvider,
        approved_start_time: dayjs(startDateTime).utc().format(),
        approved_end_time: dayjs(endDateTime).utc().format(),
      },
    };

    dispatch(
      actions.approveJitAccount({
        q: payload,
        onSuccess: () => {
          onClose();
          dispatch(actions.getJitApproveRequests({ q: {} }));
          successToast({
            title: 'Success',
            description: 'Request approved successfully',
          });
        },
      }),
    );
  };

  const handleDeny = () => {
    const payload: ItemApiDenyRequestRequest = {
      requestId: request.request_id,
      approvalDeniedInfo: {
        reason: comment ?? '',
        denied_by: info.email ?? '',
      },
    };

    dispatch(
      actions.denyJitAccount({
        q: payload,
        onSuccess: () => {
          onClose();
          dispatch(actions.getJitApproveRequests({ q: {} }));
          successToast({
            title: 'Success',
            description: 'Request denied successfully',
          });
        },
      }),
    );
  };

  const requestInfo = useMemo(() => {
    const info =
      request?.access_request_details?.[request.cloud_type.toLowerCase()];

    console.log(info);
    return {
      cloud_id: info?.cloud_account_id ?? '',
      role: info?.role ?? '',
      aws_resources: info?.aws_resources ?? [],
    };
  }, [request?.access_request_details, request.cloud_type]);

  const cloudUser = useMemo(() => getCloudUser(request, requestAccount), [
    request,
    requestAccount,
  ]);

  const cloudUserLabel = useMemo(() => {
    switch (request.cloud_type) {
      case 'AWS':
        return 'Cloud User';
      case 'GCP':
        return 'Member';
      default:
        return '-';
    }
  }, [request.cloud_type]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <HStack spacing={4}>
          <Square
            size={'32px'}
            bg={'primary'}
            color={'white'}
            p={1}
            borderRadius={4}
          >
            <ManualIcon />
          </Square>
          <Stack spacing={1}>
            <Heading4>Approve or Deny</Heading4>
            <Text fontSize="sm" fontWeight={'normal'}>
              {requestInfo.cloud_id ?? '-'}
            </Text>
          </Stack>
        </HStack>
      }
      body={
        <Stack w={'full'}>
          <Form
            schema={{
              resource: {
                type: 'custom',
                component: () => {
                  return (
                    <>
                      {requestInfo?.role && (
                        <FieldValue
                          field={'Resource'}
                          value={'Role Access / ' + (requestInfo?.role ?? '-')}
                        />
                      )}
                    </>
                  );
                },
              },
              cloud_user: {
                type: 'custom',
                component: () => {
                  return (
                    <FieldValue field={cloudUserLabel} value={cloudUser} />
                  );
                },
              },
              requester: {
                type: 'custom',
                component: () => {
                  return (
                    <FieldValue
                      field={'Requester'}
                      value={request.requester_slack_email_id ?? '-'}
                    />
                  );
                },
              },
              access_time: {
                type: 'custom',
                label: 'Access needed for',
                component: () => {
                  return (
                    <Stack>
                      <Text
                        fontFamily={theme.fonts.heading}
                        fontWeight={'bold'}
                      >
                        Access needed:
                      </Text>
                      <Stack>
                        <HStack>
                          <Text w={'90px'}>Start time: </Text>
                          <CustomDayPicker
                            dateProps={{
                              mode: 'single',
                              onSelect: selected => {
                                setStartDate(dayjs(selected!).startOf('day'));
                              },
                              selected: startDate!.toDate(),
                            }}
                          />
                          <TimePicker
                            onChange={setStartTime}
                            value={startTime}
                          />
                          {startDateTime && (
                            <Text>
                              {startDateTime
                                .utc()
                                .format('DD-MMM-YYYY HH:mm:ss')}{' '}
                              (UTC)
                            </Text>
                          )}
                        </HStack>
                        <HStack>
                          <HStack>
                            <Text w={'90px'}>End time: </Text>
                            <CustomDayPicker
                              dateProps={{
                                mode: 'single',
                                onSelect: selected => {
                                  setEndDate(dayjs(selected!).startOf('day'));
                                },
                                selected: endDate!.toDate(),
                              }}
                            />
                            <TimePicker onChange={setEndTime} value={endTime} />
                            {endDateTime && (
                              <Text>
                                {endDateTime
                                  .utc()
                                  .format('DD-MMM-YYYY HH:mm:ss')}{' '}
                                (UTC)
                              </Text>
                            )}
                          </HStack>
                        </HStack>
                      </Stack>
                    </Stack>
                  );
                },
              },
              requester_comments: {
                label: 'Requester Comments',
                type: 'custom',
                component: () => {
                  return (
                    <Stack>
                      <Text
                        fontFamily={theme.fonts.heading}
                        fontWeight={'bold'}
                      >
                        Requester Comments :
                      </Text>
                      <Text>{request.reason ?? ''}</Text>;
                    </Stack>
                  );
                },
              },
              status: {
                label: 'Status',
                type: 'custom',
                component: () => {
                  return (
                    <FieldValue
                      field={'Status'}
                      value={
                        <JitApprovalStatus status={request.status ?? ''} />
                      }
                    />
                  );
                },
              },
              approver_comments: {
                label: 'Approver Comments',
                type: 'textArea',
                placeholder: 'Add comment to deny',
                value: comment,
                onChange: e => {
                  setComment(e);
                },
              },
            }}
            handleSubmit={handleApprove}
            styles={{
              form: {
                submitButton: {
                  bg: customTheme.colors.green['300'],
                  _active: {
                    bg: customTheme.colors.green['400'],
                  },
                  _hover: {
                    bg: customTheme.colors.green['300'],
                  },
                  color: 'white',
                },
                backButton: {
                  bg: customTheme.colors.red['500'],
                  _active: {
                    bg: customTheme.colors.red['600'],
                  },
                  _hover: {
                    bg: customTheme.colors.red['500'],
                  },
                  color: 'white',
                },
              },
            }}
            buttonOptions={{
              submit: {
                name: 'Approve',
                isLoading: approveJitAccount.isLoading,
                isDisabled:
                  !startDateTime ||
                  !endDateTime ||
                  startDateTime > endDateTime ||
                  processed ||
                  !comment.trim(),
              },
              back: {
                name: 'Deny',
                isVisible: true,
                isDisabled: !comment.trim() || processed,
                onClick: handleDeny,
                isLoading: denyJitAccount.isLoading,
              },
              reset: {
                name: 'Cancel',
                isVisible: true,
                onClick: onClose,
              },
            }}
          />
        </Stack>
      }
      styles={{
        modal: { size: '2xl' },
      }}
    />
  );
};
