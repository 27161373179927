import React, { useEffect } from 'react';

import { AccessRequestGetResponse } from '@ariksa/jit';
import { Box, HStack, Square, Stack } from '@chakra-ui/react';
import jsonBeautify from 'json-beautify';
import { useDispatch, useSelector } from 'react-redux';

import { Heading4, SyntaxHighlight } from 'components/DataDisplay';
import { WithSpinner } from 'components/DataDisplay/Spinner/WithSpinner';
import { FilterIcon, PolicyIcon } from 'components/Icons';
import { Modal } from 'components/Overlay';
import { selectJit } from 'containers/Setup/Jit/selectors';
import { actions } from 'containers/Setup/Jit/slice';

interface PolicyDocumentModalProps {
  isOpen: boolean;
  onClose: () => void;
  request: AccessRequestGetResponse;
}

// @ts-ignore
const json = value => jsonBeautify(value || {}, null, 2, 10);

export const PolicyDocumentModal = (props: PolicyDocumentModalProps) => {
  const { isOpen, onClose, request } = props;

  const dispatch = useDispatch();
  const { policyDocument } = useSelector(selectJit);
  useEffect(() => {
    dispatch(
      actions.getPolicyDocument({
        q: {
          requestId: request.request_id,
        },
      }),
    );
  }, [dispatch, request.request_id]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <HStack spacing={4}>
          <Square
            size={'32px'}
            bg={'primary'}
            color={'white'}
            p={1}
            borderRadius={4}
          >
            <PolicyIcon p={'2px'} />
          </Square>
          <Heading4>Policy Document</Heading4>
        </HStack>
      }
      body={
        <Stack h={400}>
          <WithSpinner loadStatus={{ loading: policyDocument.isLoading }}>
            <SyntaxHighlight language={'json'}>
              {json(policyDocument.data)}
            </SyntaxHighlight>
          </WithSpinner>
        </Stack>
      }
      styles={{
        modal: { size: '3xl' },
      }}
    />
  );
};
