import React, { useCallback, useEffect, useReducer } from 'react';

import { CloudAccountStatus, CloudProviders } from '@ariksa/cloud-account';
import { AccountType } from '@ariksa/cloud-account/api';
import { Screens } from '@ariksa/inventory-core';
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Flex,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { filter, includes, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { INIT_PAGE_INFO } from 'services/utils/constants';
import { customTheme } from 'theme';
import { v4 as uuidv4 } from 'uuid';

import { Api, CloudAccountService } from 'api/index';
import { getIcon, PageHeaderWithIcon } from 'components/DataDisplay';
import { Form, FormSchema } from 'components/DataEntry';
import {
  ClockIcon,
  CopyIcon,
  IdeaGlitterIcon,
  LearnMoreIcon,
  QuestionCircleIcon,
  RoleIcon,
  ThirdPartyIcon,
} from 'components/Icons';
import { PageInfo } from 'components/Navigation';
import { errorToast } from 'components/Toast';
import { useEnvironmentOptions } from 'containers/App/hooks/useEnvironmentOptions';
import { DashboardOverviewTooltip } from 'containers/Dashboard/utils/OverviewDashboardTooltip';
import { accordionStyles } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/OnboardGcp/styles';
import {
  gcpPermissionList,
  renderAccordionButton,
  renderRequiredSteps,
} from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/OnboardGcp/utils';
import { selectJit } from 'containers/Setup/Jit/selectors';
import { actions } from 'containers/Setup/Jit/slice';
import { selectSetup } from 'containers/Setup/selectors';
import { actions as setupActions } from 'containers/Setup/slice';

interface JitApproverModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const JitAddAccountModal = (props: JitApproverModalProps) => {
  const { onClose } = props;
  const dispatch = useDispatch();

  const { accounts, addJitApprover } = useSelector(selectJit);
  const { configCommands } = useSelector(selectSetup);

  const { getEnvironmentOptions } = useEnvironmentOptions();
  const helpDrawer = useDisclosure();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      environmentOptions: [],
      environment: {},
      accountOptions: [],
      selectedHelpItem: {},
      copied: '',
      serviceAccountEmailID: '',
      account: {},
    },
  );

  useEffect(() => {
    dispatch(
      actions.getCloudAccounts({
        q: {
          jit: false,
          status: CloudAccountStatus.Success,
          accountType: AccountType.CloudAccount,
        },
        page: {
          page_number: 1,
          page_size: 100,
        },
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    const options = getEnvironmentOptions();
    updateState({ environmentOptions: options, environment: options?.[0] });
  }, [getEnvironmentOptions]);

  useEffect(() => {
    let options: Record<string, any>[] = map(
      filter(
        accounts.data,
        o =>
          includes(state.environment?.data?.account_ids, o.uuid) &&
          (o?.cloud_type === CloudProviders.Aws ||
            o?.cloud_type === CloudProviders.Gcp),
      ),
      a => ({
        label: a?.name + ' (Account ID: ' + a?.cloud_account_id + ')',
        icon: getIcon(a?.cloud_type!),
        value: a,
        data: a,
      }),
    );
    updateState({ accountOptions: options, account: options?.[0] });
  }, [accounts.data, state.environment]);

  const getJitAccounts = useCallback(
    (info: PageInfo = INIT_PAGE_INFO) => {
      dispatch(
        actions.getJitAccounts({
          q: {},
          page: info,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      !!state.account &&
      state.account?.data?.cloud_type === CloudProviders.Gcp
    )
      dispatch(setupActions.getConfigCommands({ q: { screen: Screens.Jit } }));
  }, [state.account, dispatch]);

  const handleSummit = data => {
    const { account } = data;
    const { cloud_type, uuid, cloud_account_id } = account?.data;

    if (cloud_type === CloudProviders.Gcp) {
      dispatch(
        actions.addJitAccount({
          q: {
            cloudType: cloud_type,
            uuid,
            gcpServiceAccount: state.serviceAccountEmailID,
          },
          onSuccess: () => onClose(),
        }),
      );
    } else if (cloud_type === CloudProviders.Aws)
      dispatch(
        actions.getOnboardingConfig({
          q: {
            cloudType: cloud_type,
          },
          onSuccess: res => {
            if (!res) {
              errorToast({
                title: 'Failed to get onboarding config',
                description: 'Please try again later',
              });
              return;
            }

            CloudAccountService.CloudAccount.onboardJitAccount({
              cloudType: cloud_type,
              uuid: uuid,
            })
              .then(r => {
                console.log(r);
                getJitAccounts();
              })
              .catch(e => {
                console.error(e);
              });

            const { aws_config } = res;

            const params: any = {
              stackName: `Ariksa-JIT-${cloud_account_id}-${uuidv4()}`,
              param_ExternalID: aws_config?.external_id,
              templateURL: aws_config?.cfs_template_url,
              param_AriksaAdminAccountId: aws_config?.account_id,
            };

            const redirectUrl = Api.awsOnboardRedirectURL(params);
            onClose();
            window.open(redirectUrl, '_blank');
          },
          onError: err => {
            errorToast({
              title: 'Failed to get onboarding config',
              description: err.description,
            });
          },
        }),
      );
  };

  const onClickAccordionButton = item => {
    helpDrawer.onOpen();
    updateState({ selectedHelpItem: item });
  };

  const steps = [
    {
      label: 'Create a service account for Ariksa',
      helpHeader: 'Create Service Account for Ariksa',
      headerIcon: <RoleIcon />,
      iconBgColor: 'primary',
      helpContent: (
        <Stack spacing={4}>
          <Stack>
            <Box fontWeight={600} fontSize="md">
              Service account must include the following roles:
            </Box>
            <Accordion allowMultiple>
              {map(gcpPermissionList, o => (
                <AccordionItem {...accordionStyles.item}>
                  {({ isExpanded }) => (
                    <>
                      {renderAccordionButton(
                        o,
                        isExpanded,
                        onClickAccordionButton,
                        false,
                      )}
                      <AccordionPanel {...accordionStyles.panel}>
                        <Box px={6} py={3}>
                          {o.description}
                        </Box>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          </Stack>
          <Box borderBottom="1px solid" borderColor="gray.200" />
          <Box fontWeight={600} pt={2} fontSize="md">
            Copy and paste the following script in Cloudshell
          </Box>
          <Box h={32} bg={customTheme.colors.gray['50']} p={4} borderRadius={6}>
            {
              configCommands.data?.cloud_commands_map?.gcp?.jit
                ?.create_service_account
            }
          </Box>
        </Stack>
      ),
      secondary: [
        {
          label: 'Use Cloud Deployment to create Service Account',
          icon: <CopyIcon p={0.5} />,
          tooltip: 'Copy command',
          copyText:
            configCommands.data?.cloud_commands_map?.gcp?.jit
              ?.create_service_account,
        },
        {
          label: 'Redirect to Google Cloud console',
          icon: <ThirdPartyIcon />,
          url: 'https://console.cloud.google.com',
          tooltip: 'Redirect to Google console. Manually execute command',
        },
      ],
    },
  ];

  const gcp: FormSchema =
    state.account?.data?.cloud_type === CloudProviders.Gcp
      ? {
          serviceAccount: {
            type: 'custom',
            component: () => (
              <Stack>
                <HStack>
                  <Box boxSize={6}>
                    <Center></Center>
                  </Box>
                  <Box fontWeight={600}>
                    Before proceeding, perform the following steps
                  </Box>
                </HStack>
                {renderRequiredSteps(
                  steps,
                  onClickAccordionButton,
                  state.copied,
                  val => updateState({ copied: val }),
                )}
              </Stack>
            ),
          },
          serviceAccountEmailID: {
            type: 'text',
            label: 'Service Account - Email ID',
            onChange: value => updateState({ serviceAccountEmailID: value }),
            helpIcon: <QuestionCircleIcon />,
            helpTooltip: 'Get Service Account Email ID',
            onClickHelpIcon: () => {
              helpDrawer.onOpen();
              updateState({
                selectedHelpItem: {
                  helpHeader: 'Get Service Account Email ID',
                  headerIcon: <RoleIcon />,
                  helpContent: (
                    <Stack spacing={4}>
                      <Box fontWeight={600} pt={2} fontSize="md">
                        Copy and paste the following script in Cloudshell
                      </Box>
                      <Box
                        h={32}
                        bg={customTheme.colors.gray['50']}
                        p={4}
                        borderRadius={6}
                      >
                        {
                          configCommands.data?.cloud_commands_map?.gcp?.jit
                            ?.fetch_service_account
                        }
                      </Box>
                    </Stack>
                  ),
                },
              });
            },
          },
        }
      : {};

  return (
    <Stack spacing={8}>
      <HStack>
        <Box w="460px">
          <PageHeaderWithIcon label="Enable JIT" icon={<ClockIcon />} />
        </Box>
        <Box>
          <DashboardOverviewTooltip
            button={
              <HStack
                border="1px solid"
                borderColor={customTheme.colors.gray['100']}
                borderRadius={6}
                px={3}
                py={1}
              >
                <Box boxSize={6} color="primary">
                  <Center>
                    <LearnMoreIcon />
                  </Center>
                </Box>
                <Box>Learn More</Box>
              </HStack>
            }
            header="Enable JIT access for account..."
            icon={<IdeaGlitterIcon />}
            footerText="Ok"
            tooltip={
              <Stack>
                <Box>
                  Just In Time or JIT access provides a policy-based framework
                  to assign granular privileges to users on-demand dynamically.
                  These privileges are typically assigned through roles for a
                  limited period.
                </Box>
                <Box>
                  You can enable JIT access for every account individually
                </Box>
              </Stack>
            }
          />
        </Box>
      </HStack>
      <Stack w="full" h="full" spacing={5}>
        <Form
          schema={{
            environment: {
              type: 'react-select',
              label: 'Select environment to enable JIT access',
              options: state.environmentOptions,
              required: true,
              value: state.environment,
              onChange: s => {
                updateState({ environment: s });
              },
            },
            account: {
              type: 'react-select',
              label: 'Account',
              isLoading: accounts.isLoading,
              isDisabled: !state.environment,
              options: state.accountOptions,
              value: state.account,
              showIconInValueContainer: true,
              required: true,
              onChange: s => {
                updateState({ account: s });
              },
            },
            ...gcp,
            info: {
              type: 'custom',
              label: '',
              component: () => (
                <Flex display="inline" pt={3}>
                  <Text color="primary" display="inline">
                    NOTE:{' '}
                  </Text>
                  <Text display="inline">
                    Ariksa will create a cross-account role with only
                    permissions to attach/detach roles from users
                  </Text>
                </Flex>
              ),
            },
          }}
          helpDrawer={{
            isOpen: helpDrawer.isOpen,
            onClose: helpDrawer.onClose,
            content: state.selectedHelpItem?.helpContent,
            header: (
              <PageHeaderWithIcon
                label={state.selectedHelpItem?.helpHeader}
                icon={state.selectedHelpItem?.headerIcon}
                iconBgColor={state.selectedHelpItem?.iconBgColor}
                useCustomColor
                reversed
              />
            ),
            styles: {
              body: { pt: 6 },
            },
          }}
          handleSubmit={handleSummit}
          buttonOptions={{
            submit: {
              name: '+ Enable JIT',
              isVisible: true,
              isDisabled:
                !state.account ||
                (state.account?.data?.cloud_type === CloudProviders.Gcp &&
                  !state.serviceAccountEmailID),
              isLoading: addJitApprover.isLoading,
            },
            reset: {
              name: 'Cancel',
              isVisible: true,
              onClick: onClose,
            },
          }}
          styles={{
            form: {
              submitButton: {
                bg: 'primary',
                color: 'white',
              },
            },
          }}
        />
      </Stack>
    </Stack>
  );
};
