import React, { useState } from 'react';

import { Box, Center, HStack, Stack, useDisclosure } from '@chakra-ui/react';
import { isEmpty, map } from 'lodash';
import round from 'lodash/round';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { NoDataAvailable } from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { EOLIcon, IconTypes } from 'components/Icons';
import { getIcon } from 'components/Icons/Components';
import { ActiveResourceProvider } from 'containers/ActiveCloudResource/context/ActiveResourceProvider';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { IdentityCard } from 'containers/Dashboard/utils/IdentityCard';
import { renderEmptyRows } from 'containers/Dashboard/utils/utils';
import { AllEOLSoftwareDrawer } from 'containers/Dashboard/VulnerabilityOverview/Components/EOLSoftware/AllEOLSoftwareDrawer';
import { IndividualEOLSoftwareDrawer } from 'containers/Dashboard/VulnerabilityOverview/Components/EOLSoftware/IndividualEOLSoftwareDrawer';
import { selectVulnerabilityOverview } from 'containers/Dashboard/VulnerabilityOverview/selectors';
import { formatDate } from 'utils/date';

export const EndOfLifeSoftware: React.FC = () => {
  const { eolSoftware } = useSelector(selectVulnerabilityOverview);
  const allEolSoftwareDisclosure = useDisclosure();
  const eolSoftwareDisclosure = useDisclosure();
  const [currentRecord, setCurrentRecord] = useState<Record<string, any>>({});

  return (
    <ActiveResourceProvider>
      <DashboardOverviewCard
        label="End-of-life software (OS and Apps)"
        icon={<EOLIcon />}
        isLoading={eolSoftware.isLoading}
        content={
          <>
            <HStack w="full" h="full" spacing={3}>
              <Box pl={2}>
                <IdentityCard
                  label="Occurrences"
                  count={
                    <HStack spacing={1}>
                      <Box>
                        {formatNumber(eolSoftware.data?.total_occurrences ?? 0)}
                      </Box>
                      <Box fontWeight={400}>
                        {'(' +
                          round(eolSoftware.data?.eol_percentage ?? 0, 0) +
                          '%)'}
                      </Box>
                    </HStack>
                  }
                  bg="#f8dddd"
                  iconType={IconTypes.ExclamationTriangle}
                  iconColor="critical"
                  cardH="11rem"
                  cardW="9rem"
                  iconBoxSize={10}
                  labelFontSize="md"
                  contentSpacing={4}
                  countFontSize="xl"
                  onClick={() => allEolSoftwareDisclosure.onOpen()}
                />
              </Box>
              {isEmpty(eolSoftware.data?.items) ? (
                <NoDataAvailable />
              ) : (
                <Stack w="full" h="full" spacing={1}>
                  <HStack
                    w="full"
                    spacing={2}
                    color={customTheme.colors.gray['300']}
                  >
                    <Box w="32%" pl={8}>
                      TECHNOLOGY
                    </Box>
                    <Box w="22%">OCCURRENCES</Box>
                    <Box w="25%">EOL</Box>
                    <Box w="21%">LATEST</Box>
                  </HStack>
                  <Box
                    borderBottom="1px solid"
                    borderColor={customTheme.colors.gray['200']}
                    fontSize="md"
                  ></Box>
                  <Stack
                    w="full"
                    h="full"
                    justify="space-between"
                    pb={2}
                    fontSize="md"
                  >
                    {map(eolSoftware.data?.items, (o, index) => (
                      <Center
                        w="full"
                        h="full"
                        key={'eol-key-' + index}
                        onClick={() => {
                          eolSoftwareDisclosure.onOpen();
                          setCurrentRecord(o);
                        }}
                        cursor="pointer"
                      >
                        <HStack w="full" spacing={2}>
                          <Box w="32%">
                            <HStack w="full">
                              <Box boxSize={6}>
                                <Center>{getIcon(o?.name)}</Center>
                              </Box>
                              <Box
                                flex={1}
                                whiteSpace="nowrap"
                                overflow="hidden"
                              >
                                <CustomTooltip
                                  label={o?.name + ' ' + o?.version}
                                >
                                  {o?.name + ' ' + o?.version}
                                </CustomTooltip>
                              </Box>
                            </HStack>
                          </Box>
                          <Box fontWeight={600} w="22%" textAlign="center">
                            {formatNumber(o?.occurrences)}
                          </Box>
                          <Box fontWeight={600} w="25%">
                            {formatDate(o.end_of_life, 'DD-MMM-YYYY')}
                          </Box>
                          <Box
                            fontWeight={600}
                            w="21%"
                            whiteSpace="nowrap"
                            overflow="hidden"
                          >
                            {o?.latest_version ? (
                              <CustomTooltip label={o?.latest_version}>
                                {o?.latest_version}
                              </CustomTooltip>
                            ) : (
                              '-'
                            )}
                          </Box>
                        </HStack>
                      </Center>
                    ))}
                    {renderEmptyRows(eolSoftware.data?.items?.length)}
                  </Stack>
                </Stack>
              )}
            </HStack>
            {allEolSoftwareDisclosure.isOpen && (
              <AllEOLSoftwareDrawer
                isOpen={allEolSoftwareDisclosure.isOpen}
                onClose={allEolSoftwareDisclosure.onClose}
              />
            )}
            {eolSoftwareDisclosure.isOpen && (
              <IndividualEOLSoftwareDrawer
                isOpen={eolSoftwareDisclosure.isOpen}
                onClose={eolSoftwareDisclosure.onClose}
                currentRecord={currentRecord}
              />
            )}
          </>
        }
        iconBgColor="critical"
      />
    </ActiveResourceProvider>
  );
};
