import React from 'react';

import { JitRole } from '@ariksa/jit';
import { Center } from '@chakra-ui/react';

import { AwsIcon, CloudIcon, GoogleCloudIcon } from 'components/Icons';

export const JitApprovalStatus = ({ status }: { status: string }) => {
  return (
    <Center
      borderRadius={4}
      color="white"
      w={20}
      bg={
        status === 'DECLINED'
          ? 'critical'
          : status === 'APPROVED'
          ? 'green.300'
          : '#aaa'
      }
    >
      {status?.toUpperCase()}
    </Center>
  );
};

export const getCloudUser = (request, requestAccount) => {
  switch (request.cloud_type) {
    case 'AWS':
      return requestAccount.aws_username;
    case 'GCP':
      return `user:${request.requester_slack_email_id}`;
    default:
      return '-';
  }
};

export const renderCloudIcon = (cloudType: string) => {
  switch (cloudType) {
    case 'aws':
      return <AwsIcon />;
    case 'gcp':
      return <GoogleCloudIcon />;
    default:
      return <CloudIcon />;
  }
};

export const roleNameOption = (request: JitRole, cloudType: string) => {
  switch (cloudType) {
    case 'AWS':
      return {
        label: `${request.role}`,
        value: request.role_arn,
      };
    case 'GCP':
      return {
        label: `${request.role} (${request.role_id
          ?.split('/')
          .slice(-2)
          .join('/')})`,
        value: request.role_id,
      };
    default:
      return null;
  }
};
