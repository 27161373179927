import React, { FC, useEffect, useState } from 'react';

import { VulnerabilityPriortization } from '@ariksa/scan-analysis/api';
import { Box } from '@chakra-ui/react';
import { IQueryState } from 'services/utils/QueryState';

import { MonitoringIcon } from 'components/Icons';
import FunnelChart from 'components/Visualization/Charts/FunnelChart';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { renderCloudIcons } from 'containers/Dashboard/utils/utils';

interface Props {
  vulnerabilityPrioritization: IQueryState<VulnerabilityPriortization>;
}

export const VulnerabilitiesPrioritizationComponent: FC<Props> = props => {
  const { vulnerabilityPrioritization } = props;
  const [data, setData] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    const items = vulnerabilityPrioritization.data;
    setData([
      { name: 'Vulnerable packages', value: items.vulnerable_packages },
      {
        name: 'Internet exposed packages',
        value: items.internet_exposed_resource_packages,
      },
      {
        name: 'Exploitable packages',
        value: items.internet_exposed_resource_packages_with_exploitable_cve,
      },
      {
        name: 'Patchable packages',
        value:
          items.internet_exposed_resource_patchable_packages_with_exploitable_cve,
      },
      {
        name: 'Hosts to patch',
        value:
          items.internet_exposed_resources_having_patchable_packages_with_explitable_cve,
      },
    ]);
  }, [vulnerabilityPrioritization.data]);

  return (
    <DashboardOverviewCard
      label="Vulnerability Prioritization"
      icon={<MonitoringIcon />}
      iconBgColor="critical"
      content={
        <Box w="full" h="full" px={4}>
          <FunnelChart
            data={data}
            isLoading={vulnerabilityPrioritization.isLoading}
            dataKey="value"
            labelKey="name"
          />
        </Box>
      }
      rightHeader={renderCloudIcons(vulnerabilityPrioritization.data?.clouds)}
    />
  );
};
