import { call, put, select, takeLatest } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { InventoryService } from 'api/services';

import { actions } from './slice';

export function* jitRequestsSaga() {
  yield takeLatestAction(actions.getJitRequests, doGetJitRequests);
}

function* doGetJitRequests(ctx: QueryContext<any>) {
  yield call(ctx.fetch, () => InventoryService.Search.search(ctx.params));
}
