import React, { ReactNode } from 'react';

import { Box, Center, Stack } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { getIcon } from 'components/Icons/Components';
import { CountFontSize } from 'containers/Dashboard/types';

export interface IdentityCardProps {
  label: string;
  count: ReactNode;
  isLoading?: boolean;
  iconType?: string;
  iconTooltip?: ReactNode;
  iconPaddingTop?: string;
  onClick?();
  bg: string;
}

export const CloudComplianceCard: React.FC<IdentityCardProps> = props => {
  const { iconTooltip, label, iconType, bg, onClick, count, isLoading } = props;

  return (
    <Center w="full" h="full" pt={4} pb={1}>
      <Stack spacing={0} h="full" w="full">
        <Center>
          <Box
            boxSize={10}
            position="absolute"
            borderRadius="full"
            bg="white"
          />
        </Center>
        <Center w="full" position="relative">
          <Box
            boxSize={10}
            bg="white"
            p={2}
            position="absolute"
            borderRadius="full"
            pt={props.iconPaddingTop ?? 1.5}
          >
            <CustomTooltip
              label={iconTooltip ?? label}
              w={24}
              textAlign="center"
            >
              <Center>{iconType && getIcon(iconType)}</Center>
            </CustomTooltip>
          </Box>
        </Center>
        <Box
          w="full"
          h="full"
          border="1px solid"
          borderColor={customTheme.colors.gray['200']}
          borderRadius={6}
          bg={bg}
          fontSize="md"
          onClick={onClick}
          cursor={onClick ? 'pointer' : 'default'}
          overflow="visible"
        >
          <Stack spacing={0} h="full" pt={2}>
            <Center h="50%">
              <Center fontSize="16px" textAlign="center" pt={2}>
                {label}
              </Center>
            </Center>
            <Stack spacing={0} h="50%" textAlign="center">
              <Box>Passed</Box>
              <Box fontWeight={600} fontSize={CountFontSize}>
                {isLoading ? (
                  <CustomSpinner color="white" />
                ) : (
                  (count ?? 0) + '%'
                )}
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Center>
  );
};
