import React, { FC, ReactNode, useState } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, Flex, Stack, Text, HStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import {
  isArray,
  isBoolean,
  isEmpty,
  isNumber,
  isObject,
  isUndefined,
  isString,
} from 'lodash';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { Tag } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import {
  formatBoolean,
  formatBooleanWithDot,
} from 'containers/ActiveCloudResource/Components/NodeMetadataDrawer/utils';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';

export interface MetadataFieldProps {
  label: string | ReactNode;
  value: any;
  withDot?: boolean;
  oppositeColor?: boolean;
  enableLabel?: boolean;
  withListAsTooltip?: boolean;
  custom?: boolean;
  time?: 'unix' | 'utc' | 'dayjs';
  linkResourceType?: NativeResources;
  linkResourceIDType?: 'arn' | 'cloud_id' | 'uuid';
}

export const MetadataField: FC<MetadataFieldProps> = props => {
  const {
    label,
    value,
    withDot = false,
    oppositeColor = false,
    enableLabel,
    withListAsTooltip,
    custom,
    time,
    linkResourceType,
    linkResourceIDType = 'cloud_id',
  } = props;

  const dispatch = useDispatch();
  const { updateActiveResource } = useActiveResourceActions();
  const { linkedResourceDetails } = useSelector(selectActiveResource);
  const { isLoading } = linkedResourceDetails;

  const [currentID, setCurrentID] = useState('');
  const [showMore, setShowMore] = useState(false);

  const formatValue = val => {
    let name = val;
    let id = val;
    let accountId: string | undefined = undefined;

    if (!isString(val)) {
      name = val?.name;
      id = val?.id;
      accountId = val?.accountId;
    }
    if (!!linkResourceType && !!id) {
      return (
        <HStack>
          <Text
            as="u"
            color={isLoading ? customTheme.colors.gray['200'] : 'primary'}
            cursor={isLoading ? 'not-allowed' : 'pointer'}
            onClick={() => {
              if (!!accountId) {
                updateActiveResource({
                  resourceType: linkResourceType,
                  uuid: id,
                  accountId,
                });
              } else {
                setCurrentID(id);
                let payload =
                  linkResourceIDType === 'arn'
                    ? { arns: [id] }
                    : { resource_ids: [id] };

                !isLoading &&
                  dispatch(
                    actions.getLinkedResourceDetails({
                      q: {
                        insightV2Request: {
                          ...payload,
                          resource_type: linkResourceType,
                        },
                      },
                      onSuccess: res => {
                        const data = res.data;
                        if (!isEmpty(data?.items)) {
                          updateActiveResource({
                            resourceId: data?.items?.[0].resource_id,
                            resourceType: linkResourceType,
                            uuid: data?.items?.[0].uuid,
                            accountId: data?.items?.[0].account,
                          });
                        }
                      },
                    }),
                  );
              }
            }}
          >
            {name}
          </Text>
          {linkedResourceDetails.isLoading && id === currentID && (
            <Box>
              <CustomSpinner loading={linkedResourceDetails.isLoading} />
            </Box>
          )}
        </HStack>
      );
    }
    return val?.name ?? val;
  };

  const getValue = () => {
    if (custom) return value;
    if (time) {
      if (!value) return 'None';
      switch (time) {
        case 'unix':
          return dayjs.unix(value).format('llll');
        case 'utc':
          return dayjs.utc(value).format('llll');
        case 'dayjs':
          return dayjs(value).format('llll');
      }
    }
    if (isUndefined(value)) return 'None';
    else if (isArray(value)) {
      if (withListAsTooltip && !!value?.length)
        return (
          <CustomTooltip
            borderRadius="6"
            label={
              <Stack>
                {map(value, o => (
                  <Box display="flex">
                    {o?.Key ? <Text color="orange.300">{o?.Key}</Text> : o}
                    <Text>{o?.Value}</Text>
                  </Box>
                ))}
              </Stack>
            }
          >
            {value?.length}
          </CustomTooltip>
        );
      return value?.length ? (
        <Stack spacing={0}>
          {map(value.slice(0, 2), o => (
            <Box>{formatValue(o)}</Box>
          ))}
          {showMore &&
            map(value.slice(2, value?.length), o => (
              <Box>{formatValue(o)}</Box>
            ))}
          {value?.length > 2 && !showMore && (
            <Flex>
              <CustomTooltip label="Click to show more">
                <Box cursor="pointer" onClick={() => setShowMore(true)}>
                  <Tag
                    label={'+ ' + (value?.length - 2)}
                    styles={{
                      label: { color: 'white' },
                      tag: { bg: 'primary' },
                    }}
                  />
                </Box>
              </CustomTooltip>
            </Flex>
          )}
          {showMore && (
            <Box
              onClick={() => setShowMore(false)}
              color="primary"
              cursor="pointer"
            >
              Show less
            </Box>
          )}
        </Stack>
      ) : (
        'None'
      );
    } else if (isNumber(value)) {
      return value;
    } else if (isBoolean(value)) {
      if (withDot) {
        return formatBooleanWithDot(value, oppositeColor, enableLabel);
      } else {
        return formatBoolean(value, enableLabel);
      }
    } else if (isObject(value)) {
      let localValue: any = [];

      for (const key in value) {
        localValue.push(value?.[key]);
      }

      return localValue;
    } else {
      return value ? formatValue(value) : 'None';
    }
  };

  return (
    <Stack spacing={0}>
      <Box fontWeight={600}>{label}</Box>
      <Box>{getValue()}</Box>
    </Stack>
  );
};
