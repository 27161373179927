import React, { useEffect, useMemo, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { JitAccounts } from 'app/containers/Setup/Jit/Components/Accounts';
import { AccountIcon, PermissionsActionsIcon } from 'components/Icons';
import { usePageContentContext } from 'components/Layout';
import {
  SideNav,
  SideNavKey,
  SideNavMenuItemProps,
} from 'components/Navigation';
import { AccessRequests } from 'containers/Setup/Jit/Components/AccessRequest';
import { jitSaga } from 'containers/Setup/Jit/saga';
import { reducer, sliceKey } from 'containers/Setup/Jit/slice';
import { useSearchParams } from 'hooks/useSearchParams';
import { useInjector } from 'utils/inject';

export const Jit = () => {
  useInjector(sliceKey, reducer, jitSaga);
  const navigate = useNavigate();
  const { contentHeight } = usePageContentContext();
  const [currentTab, setCurrentTab] = useState<SideNavKey>(
    'jit-access-request',
  );
  const params = useSearchParams<{ tab: string }>();

  useEffect(() => {
    if (!!params.tab) setCurrentTab(params.tab);
  }, [params.tab]);

  const menuItems: SideNavMenuItemProps[] = useMemo(() => {
    return [
      {
        title: 'Access Requests',
        key: 'jit-access-request',
        icon: <PermissionsActionsIcon p="1px" color="primary" />,
        component: <AccessRequests />,
      },
      {
        title: 'Accounts',
        key: 'jit-accounts',
        icon: <AccountIcon color="primary" />,
        component: <JitAccounts />,
      },
    ];
  }, []);

  return (
    <Box h={contentHeight + 'px'}>
      <SideNav
        menuItems={menuItems}
        current={currentTab}
        showContentHeader={false}
        onChange={key => {
          setCurrentTab(key as SideNavKey);
          navigate(`/setup/jit?tab=${key}`);
        }}
      />
    </Box>
  );
};
