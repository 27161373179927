import React, { useCallback, useMemo } from 'react';

import { AccessRequestGetResponse } from '@ariksa/jit';
import { HStack, Square, Stack, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { INIT_PAGE_INFO } from 'services/utils/constants';

import { Heading4 } from 'components/DataDisplay';
import { FieldValue } from 'components/DataDisplay/Text/FieldValueText';
import { PrimaryButton } from 'components/DataEntry';
import { ManualIcon } from 'components/Icons/ReactResourceIcons/ManualIcon';
import { Modal } from 'components/Overlay';
import { selectOnboardedCloudAccounts } from 'containers/App/selectors';
import { selectJit } from 'containers/Setup/Jit/selectors';
import { actions } from 'containers/Setup/Jit/slice';

interface AutoApproveModalProps {
  isOpen: boolean;
  onClose: () => void;
  request: AccessRequestGetResponse;
}

export const AutoApproveModal = (props: AutoApproveModalProps) => {
  const { isOpen, onClose, request } = props;
  const dispatch = useDispatch();
  const accounts = useSelector(selectOnboardedCloudAccounts);
  const { updateAutoApprovals } = useSelector(selectJit);

  const requestAccount = useMemo(() => {
    return request.access_request_details?.[request.cloud_type.toLowerCase()];
  }, [request]);

  const cloudAccount = accounts.data.find(
    account => account.uuid === requestAccount?.account_id,
  );

  const handleUpdate = useCallback(() => {
    console.log('Update', request);
    dispatch(
      actions.updateAutoApprovals({
        q: {
          requestId: request.request_id,
          toggleAutoApproveRequest: {
            auto_approve: !request.auto_approve,
          },
        },
        onSuccess: () => {
          onClose();
          dispatch(
            actions.getJitApproveRequests({
              q: {},
              page: INIT_PAGE_INFO,
            }),
          );
        },
      }),
    );
  }, [dispatch, onClose, request]);

  const cloudUser = useMemo(() => {
    switch (request.cloud_type) {
      case 'AWS':
        return requestAccount.aws_username;
      case 'GCP':
        return requestAccount.aws_username;
      default:
        return '-';
    }
  }, [request.cloud_type, requestAccount.aws_username]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <HStack spacing={4}>
          <Square
            size={'32px'}
            bg={'primary'}
            color={'white'}
            p={1}
            borderRadius={4}
          >
            <ManualIcon />
          </Square>
          <Stack spacing={1}>
            <Heading4>Change Auto-Approval Mode</Heading4>
          </Stack>
        </HStack>
      }
      body={
        <Stack w={'full'} spacing={10}>
          <Stack>
            <FieldValue
              field={'Requester'}
              value={request.requester_slack_email_id ?? '-'}
            />
            <FieldValue field={'Cloud user'} value={cloudUser} />
            <FieldValue
              field={'Cloud account'}
              value={cloudAccount?.cloud_account_id}
            />
            <FieldValue field={'Role'} value={requestAccount?.role} />
            <FieldValue
              field={'Change auto-approval to'}
              value={request.auto_approve ? 'No' : 'Yes'}
            />
          </Stack>

          <Stack color={'primary'}>
            <Heading4>Note</Heading4>
            <Text>
              For any future requests with the same information as above, you
              are changing how approval will be done. You can change this
              configuration at any time.
            </Text>
          </Stack>
        </Stack>
      }
      footer={
        <HStack w={'full'} justify={'space-between'}>
          <PrimaryButton onClick={onClose}>Cancel</PrimaryButton>
          <PrimaryButton
            onClick={handleUpdate}
            isLoading={updateAutoApprovals.isLoading}
          >
            Ok
          </PrimaryButton>
        </HStack>
      }
      styles={{
        modal: { size: 'xl' },
      }}
    />
  );
};
