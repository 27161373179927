import React, { useState } from 'react';

import {
  Box,
  BoxProps,
  Center,
  HStack,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { isEmpty, map } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  NoDataAvailable,
  renderTooltipFieldValue,
  Tag,
  WithResourceIcon,
} from 'components/DataDisplay';
import { PrimaryButton } from 'components/DataEntry';
import { IconTypes, RemediateIcon, ThirdPartyIcon } from 'components/Icons';
import { ActiveResourceProvider } from 'containers/ActiveCloudResource/context/ActiveResourceProvider';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { renderEmptyRows } from 'containers/Dashboard/utils/utils';
import { renderCloudEntities } from 'containers/Visibility/Vulnerabilities/Components/hooks/utils';
import { CVEHostsDrawer } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityTable/CVEHostsDrawer';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';

export const PatchablePackages: React.FC = () => {
  const { vulnerabilities } = useSelector(selectVulnerabilities);
  const detailsDisclosure = useDisclosure();
  const [currentRecord, setCurrentRecord] = useState<Record<string, any>>({});
  const navigate = useNavigate();
  const location = useLocation();

  const textOverflowStyles: BoxProps = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    w: 'full',
  };

  return (
    <ActiveResourceProvider>
      <DashboardOverviewCard
        label="Patchable packages with critical CVEs"
        icon={<RemediateIcon />}
        isLoading={vulnerabilities.isLoading}
        styles={{ contentWrapper: { overflow: 'hidden' } }}
        rightHeader={
          <PrimaryButton
            rightIcon={<ThirdPartyIcon />}
            onClick={() =>
              navigate(
                `${location.pathname}?tab=${IconTypes.Packages}&isPatchable=yes`,
              )
            }
            iconSize={6}
          >
            View all
          </PrimaryButton>
        }
        content={
          <>
            {isEmpty(vulnerabilities.data) ? (
              <NoDataAvailable />
            ) : (
              <Stack w="full" h="full" spacing={1} overflow="scroll">
                <HStack w="full" spacing={6} color="gray.300">
                  <Box w="40%" pl={10}>
                    PACKAGE
                  </Box>
                  <Box w="30%">CVE</Box>
                  <Box w="30%">ENTITIES</Box>
                </HStack>
                <Box
                  borderBottom="1px solid"
                  borderColor="gray.200"
                  fontSize="md"
                ></Box>
                <Stack
                  w="full"
                  h="full"
                  justify="space-between"
                  pb={2}
                  fontSize="md"
                  overflow="scroll"
                >
                  {map(vulnerabilities.data, (o, index) => (
                    <Center
                      w="full"
                      h="full"
                      key={'eol-key-' + index}
                      onClick={() => {
                        detailsDisclosure.onOpen();
                        setCurrentRecord(o);
                      }}
                      cursor="pointer"
                    >
                      <HStack w="full" spacing={6}>
                        <Box w="40%" overflow="hidden">
                          <WithResourceIcon
                            resourceType={
                              o?.package_category_name || o?.package_category
                            }
                            iconFilled={false}
                            iconSize="regular"
                            iconStyles={{ color: 'primary' }}
                            tooltip={
                              <Stack>
                                {renderTooltipFieldValue(
                                  'Name',
                                  o?.package_name,
                                )}
                                {renderTooltipFieldValue(
                                  'Version',
                                  o?.installed_version,
                                )}
                              </Stack>
                            }
                          >
                            <Stack spacing={0} w="full">
                              <Box color="primary" {...textOverflowStyles}>
                                {o?.package_name}
                              </Box>
                              <Box {...textOverflowStyles}>
                                {o?.installed_version}
                              </Box>
                            </Stack>
                          </WithResourceIcon>
                        </Box>
                        <Box w="30%">
                          <HStack w="full" align="flex-start">
                            {o?.cve_ids?.[0] && <Box>{o?.cve_ids?.[0]}</Box>}
                            {o?.cve_ids?.length > 1 && (
                              <Tag
                                label={'+' + (o?.cve_ids?.length - 1)}
                                styles={{
                                  tag: { bg: 'primary' },
                                  label: { color: 'white' },
                                }}
                              />
                            )}
                          </HStack>
                        </Box>
                        <Box w="30%">
                          {renderCloudEntities({ value: o?.entities })}
                        </Box>
                      </HStack>
                    </Center>
                  ))}
                  {renderEmptyRows(vulnerabilities.data?.length)}
                </Stack>
              </Stack>
            )}
            {detailsDisclosure.isOpen && (
              <CVEHostsDrawer
                row={currentRecord}
                isOpen={detailsDisclosure.isOpen}
                onClose={detailsDisclosure.onClose}
                selectedTab={IconTypes.Packages}
              />
            )}
          </>
        }
        iconBgColor="green.300"
      />
    </ActiveResourceProvider>
  );
};
