import React from 'react';

import { AlertResponse } from '@ariksa/notification/api';
import { Box, Stack } from '@chakra-ui/react';

import {
  Heading4,
  renderSeverityBar,
  StackedHeader,
} from 'components/DataDisplay';
import { Drawer } from 'components/Overlay';
import { SeverityComponent } from 'containers/Compliance/Components/SeverityComponent';
import { AlertDetailsContent } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Aggregated/AlertDetailsContent';
import { AlertDrawerHeader } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/AlertDrawerHeader';
import { Severity } from 'containers/PolicyHub/AlertWorkflow/Components/AlertWorkflowWizard/AlertWorkflowConditions/AlertConditions/Severity';

interface AlertDetailsDrawerProps {
  alert: AlertResponse;
  isOpen: boolean;
  onClose: () => void;
}

export const AlertDetailsDrawer = (props: AlertDetailsDrawerProps) => {
  const { alert, isOpen, onClose } = props;

  console.log(alert.resource);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      header={
        <Stack justify={'space-between'}>
          <StackedHeader
            type={alert.resource}
            upper={alert.message}
            lower={renderSeverityBar({
              value: alert.severity,
              labelStyles: {
                fontSize: 'md',
              },
            })}
          />
        </Stack>
      }
      body={
        <Box h="full" w="full" pt={3}>
          <AlertDetailsContent alert={alert} />
        </Box>
      }
      closeButton
      styles={{
        content: { maxW: '1370px' },
        drawer: {
          portalProps: {},
        },
      }}
    />
  );
};
