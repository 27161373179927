import {
  ItemApiListAllRequestsRequest,
  PageAccessRequestGetResponse,
} from '@ariksa/jit';
import { PayloadAction } from '@reduxjs/toolkit';
import { QueryAction } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';

import { PageInfo } from 'components/Navigation';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Settings container
export const initialState: ContainerState = {
  jitRequests: PagedQueryState.init({}),
};

const jitRequestsSlice = createSlice({
  name: 'jitRequests',
  initialState,
  reducers: {
    getJitRequests(
      state,
      action: QueryAction<
        PageAccessRequestGetResponse,
        ItemApiListAllRequestsRequest
      >,
    ) {
      state.jitRequests = PagedQueryState.next(state.jitRequests, action, {
        mapTotalCount: r => r.total || 0,
        mapData: r => r.items,
      });
    },

    updateJitRequestsPagination(state, action: PayloadAction<PageInfo>) {
      state.jitRequests.page.info = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = jitRequestsSlice;
