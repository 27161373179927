import {
  AuditTrailServiceApiAuditTrailServiceDataSourcesInsightRequest,
  V1DataSourcesInsightResponse,
} from '@ariksa/audittrail';
import {
  DataSourcesWithData,
  ResourceDistributionCount,
  ResourcesApiGetSourcesDistributionCountRequest,
  TrendSourcesInfo,
  VisibilityDataApiGetDataSourcesWithSensitiveDataRequest,
  VisibilityDataApiGetTrendSourcesInfoRequest,
} from '@ariksa/data-scanning/api';
import {
  DataSourcesSecurityAttributes,
  PublicResourceSummary,
  ResourceApiGetDataSourcesExposedAndUnencryptedItemsRequest,
  ResourceApiGetDataSourcesInsightsRequest,
  ResourceSummaryApiGetPublicExposedResSummaryRequest,
} from '@ariksa/inventory-core/api';
import {
  AggregatedAlertsSummary,
  AlertsApiGetFindingsCategoriesSummaryRequest,
} from '@ariksa/notification/api';
import {
  ChartsApiGetDashboardResourcesHistoryRequest,
  ChartsApiGetDataSourcesCountRequest,
  DataSourcesCount,
  InventoryApiGetResourceCountRequest,
  ResourceCountResponse,
} from '@ariksa/reporting/api';
import { QueryAction } from 'services/types';
import { QueryState } from 'services/utils/QueryState';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Tag container
export const initialState: ContainerState = {
  //data security
  dataSecurity: {
    bucketCount: QueryState.init({} as ResourceCountResponse),
    dbCount: QueryState.init({} as ResourceCountResponse),
    othersCount: QueryState.init({} as ResourceCountResponse),
    saasCount: QueryState.init({} as ResourceCountResponse),
  },
  dataSecurityFindingsCategorySummary: QueryState.init([]),
  publiclyExposedDataSources: QueryState.init([]),
  sensitiveDataBySources: QueryState.init({}),

  scannedDataSources: QueryState.init({} as DataSourcesWithData),
  dataSourcesSummaryCount: QueryState.init({} as DataSourcesCount),
  dataSourcesAnalyzed: QueryState.init({} as TrendSourcesInfo),
  dataSourcesHistory: QueryState.init({}),
  insightsForDataSources: {
    exposedAndUnencrypted: QueryState.init({}),
    inactiveAndAnomaly: QueryState.init({}),
  },
};

const dataSecurityOverviewSlice = createSlice({
  name: 'dataSecurityOverview',
  initialState,
  reducers: {
    getBucketCount(
      state,
      action: QueryAction<
        ResourceCountResponse,
        InventoryApiGetResourceCountRequest
      >,
    ) {
      state.dataSecurity.bucketCount = QueryState.next(
        state.dataSecurity.bucketCount,
        action,
      );
    },
    getDBCount(
      state,
      action: QueryAction<
        ResourceCountResponse,
        InventoryApiGetResourceCountRequest
      >,
    ) {
      state.dataSecurity.dbCount = QueryState.next(
        state.dataSecurity.dbCount,
        action,
      );
    },
    getOtherDataSourcesCount(
      state,
      action: QueryAction<
        ResourceCountResponse,
        InventoryApiGetResourceCountRequest
      >,
    ) {
      state.dataSecurity.othersCount = QueryState.next(
        state.dataSecurity.othersCount,
        action,
      );
    },
    getDataSecuritySaaSCount(
      state,
      action: QueryAction<
        ResourceCountResponse,
        InventoryApiGetResourceCountRequest
      >,
    ) {
      state.dataSecurity.saasCount = QueryState.next(
        state.dataSecurity.saasCount,
        action,
      );
    },

    getScannedDataSources(
      state,
      action: QueryAction<
        DataSourcesWithData,
        VisibilityDataApiGetDataSourcesWithSensitiveDataRequest
      >,
    ) {
      state.scannedDataSources = QueryState.next(
        state.scannedDataSources,
        action,
      );
    },

    getDataSecurityFindingsCategorySummary(
      state,
      action: QueryAction<
        AggregatedAlertsSummary[],
        AlertsApiGetFindingsCategoriesSummaryRequest
      >,
    ) {
      state.dataSecurityFindingsCategorySummary = QueryState.next(
        state.dataSecurityFindingsCategorySummary,
        action,
      );
    },

    getDataSourcesSummaryCount(
      state,
      action: QueryAction<
        DataSourcesCount,
        ChartsApiGetDataSourcesCountRequest
      >,
    ) {
      state.dataSourcesSummaryCount = QueryState.next(
        state.dataSourcesSummaryCount,
        action,
      );
    },

    getDataSourcesHistory(
      state,
      action: QueryAction<
        Record<string, any>,
        ChartsApiGetDashboardResourcesHistoryRequest
      >,
    ) {
      state.dataSourcesHistory = QueryState.next(
        state.dataSourcesHistory,
        action,
      );
    },

    getPubliclyExposedDataSources(
      state,
      action: QueryAction<
        PublicResourceSummary[],
        ResourceSummaryApiGetPublicExposedResSummaryRequest
      >,
    ) {
      state.publiclyExposedDataSources = QueryState.next(
        state.publiclyExposedDataSources,
        action,
      );
    },

    getDataSourcesAnalyzedInfo(
      state,
      action: QueryAction<
        TrendSourcesInfo,
        VisibilityDataApiGetTrendSourcesInfoRequest
      >,
    ) {
      state.dataSourcesAnalyzed = QueryState.next(
        state.dataSourcesAnalyzed,
        action,
      );
    },

    getSensitiveDataBySources(
      state,
      action: QueryAction<
        ResourceDistributionCount,
        ResourcesApiGetSourcesDistributionCountRequest
      >,
    ) {
      state.sensitiveDataBySources = QueryState.next(
        state.sensitiveDataBySources,
        action,
      );
    },

    getExposedAndUnencryptedDataSources(
      state,
      action: QueryAction<
        DataSourcesSecurityAttributes,
        ResourceApiGetDataSourcesInsightsRequest
      >,
    ) {
      state.insightsForDataSources.exposedAndUnencrypted = QueryState.next(
        state.insightsForDataSources.exposedAndUnencrypted,
        action,
      );
    },

    getInactiveAndHasAnomalyDataSources(
      state,
      action: QueryAction<
        V1DataSourcesInsightResponse,
        AuditTrailServiceApiAuditTrailServiceDataSourcesInsightRequest
      >,
    ) {
      state.insightsForDataSources.inactiveAndAnomaly = QueryState.next(
        state.insightsForDataSources.inactiveAndAnomaly,
        action,
      );
    },
  },
});

export const { actions, reducer, name: sliceKey } = dataSecurityOverviewSlice;
