import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { NativeResources } from '@ariksa/inventory-core';
import { AccessRequestGetResponse } from '@ariksa/jit/api';
import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Square,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { filter, identity } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { INIT_PAGE_INFO } from 'services/utils/constants';
import { Optional } from 'types/utils';

import {
  dateLabel,
  PageHeaderWithIcon,
  StackedCell,
  Tag,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { FieldValue } from 'components/DataDisplay/Text/FieldValueText';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { SwitchOnOff } from 'components/Elements/SwitchIO/SwitchOnOff';
import {
  IconTypes,
  PermissionsActionsIcon,
  PolicyIcon,
  SlackIcon,
  ViewAllIcon,
} from 'components/Icons';
import { ManualIcon } from 'components/Icons/ReactResourceIcons/ManualIcon';
import { TableActionsMenu } from 'components/Overlay';
import {
  getHighPrivilegeColor,
  highPrivilegesConst,
  renderHighPrivileges,
} from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/utils';
import { ResourceActionsDrawer } from 'containers/Setup/Jit/Components/ResourceActionsDrawer';
import { selectJit } from 'containers/Setup/Jit/selectors';
import { actions } from 'containers/Setup/Jit/slice';
import {
  getCloudUser,
  JitApprovalStatus,
  renderCloudIcon,
} from 'containers/Setup/Jit/utils';
import { ApproveDenyModal } from 'containers/Visibility/JitRequests/Components/ApproveDenyModal';
import { AutoApproveModal } from 'containers/Visibility/JitRequests/Components/AutoApproveModal';
import { PolicyDocumentModal } from 'containers/Visibility/JitRequests/Components/PolicyDocumentModal';

export const AccessRequests = () => {
  const dispatch = useDispatch();
  const [activeRow, setActiveRow] = useState<
    Optional<AccessRequestGetResponse>
  >(null);
  const approveDenyModal = useDisclosure();
  const policyDocumentModal = useDisclosure();
  const autoApprove = useDisclosure();
  const metadataDrawer = useDisclosure();

  const { jitApproveRequests } = useSelector(selectJit);

  const requestAccount = useCallback(row => {
    return row?.access_request_details?.[row.cloud_type.toLowerCase()] ?? {};
  }, []);

  const handleAutoApprove = useCallback(
    (e, row) => {
      e.preventDefault();
      e.stopPropagation();
      setActiveRow(row);
      autoApprove.onOpen();
    },
    [autoApprove],
  );

  const columns: TableColumnProps<AccessRequestGetResponse>[] = useMemo(() => {
    return [
      {
        header: <Box pl="thLeftPaddingWithIcon">Requester</Box>,
        render: ({ row }) => {
          return (
            <WithResourceIcon resourceType={IconTypes.IamRole}>
              {getCloudUser(row, requestAccount(row))}
            </WithResourceIcon>
          );
        },
      },
      {
        header: 'Account',
        align: 'left',
        render: ({ row }) => {
          return (
            <StackedCell
              upper={
                <Box boxSize={5}>
                  <Center bg={'transparent'}>
                    {renderCloudIcon(row.cloud_type?.toLowerCase() ?? '')}
                  </Center>
                </Box>
              }
              lower={requestAccount(row)?.cloud_account_id ?? ''}
            />
          );
        },
      },
      {
        header: 'Resource',
        align: 'left',
        sortKey: 'x',
        render: ({ row }) => {
          const cloudDetails = requestAccount(row);
          if (cloudDetails.aws_resources) {
            const resources = cloudDetails.aws_resources
              ?.map(r => r.resource_type ?? '')
              .filter(identity) as string[];
            return (
              <StackedCell
                upper={'Custom'}
                lower={
                  <Flex align={'start'} gap={2} overflow={'wrap'}>
                    {resources.slice(0, 3).map(r => (
                      <Tag key={r} label={r} />
                    ))}
                    {resources.length > 3 && (
                      <CustomTooltip
                        label={
                          <Stack>
                            {resources.slice(3).map(r => (
                              <Tag key={r} label={r} />
                            ))}
                          </Stack>
                        }
                      >
                        <Tag label={`+${resources.length - 3}`} />
                      </CustomTooltip>
                    )}
                  </Flex>
                }
                showLowerTooltip={false}
              />
            );
          }

          if (cloudDetails.role) {
            return (
              <StackedCell
                upper={cloudDetails.role_name ?? '-'}
                lower={<Text>{cloudDetails.role ?? '-'}</Text>}
                showLowerTooltip={false}
              />
            );
          }

          return (
            <StackedCell
              upper={'-'}
              lower={<Text>{'-'}</Text>}
              showLowerTooltip={false}
            />
          );
        },
      },
      {
        header: 'Role Permissions',
        align: 'left',
        sortKey: 'x',
        render: ({ row }) => {
          const permissions = requestAccount(row)?.crud_counts ?? {};
          return (
            <Flex align={'start'} gap={1} overflow={'wrap'}>
              {renderHighPrivileges({
                value: permissions,
              })}
            </Flex>
          );
        },
      },
      {
        header: 'Received',
        accessor: 'request_creation_time',
        render: ({ row, value }) => {
          return (
            <StackedCell
              upper={dateLabel(value)}
              lower={
                <Center gap={2}>
                  <Text>{dayjs.utc(value).local().format('hh:mm A')}</Text>
                  <Square size={4}>
                    <SlackIcon />
                  </Square>
                </Center>
              }
              showLowerTooltip={false}
              showUpperTooltip={false}
            />
          );
        },
      },
      {
        header: 'Auto-Approval',
        render: ({ row }) => {
          return (
            <Center gap={2}>
              <SwitchOnOff
                value={row.auto_approve ? 1 : 0}
                onClick={e => handleAutoApprove(e, row)}
              />
            </Center>
          );
        },
      },
      {
        header: 'Status',
        accessor: 'status',
        align: 'center',
        render: ({ row, value }) => {
          return (
            <Center w="full" fontSize="xs">
              <JitApprovalStatus status={value} />
            </Center>
          );
        },
      },
      {
        header: 'Duration',
        accessor: 'duration',
        render: ({ row }) => {
          const { requested_end_time, requested_start_time } = row;
          if (!requested_start_time || !requested_end_time) return;
          const start = dayjs(requested_start_time);
          const end = dayjs(requested_end_time);
          const duration = end.diff(start, 'minute');

          const hours = Math.floor(duration / 60);
          const minute = duration % 60;
          const durationLabel =
            minute === 0 ? `${hours}h` : `${hours}h ${minute}`;
          return (
            <Stack spacing={1}>
              <Text>{durationLabel}</Text>
            </Stack>
          );
        },
      },
      {
        header: 'Actions',
        align: 'center',
        render: ({ row }) => {
          return (
            <Box w="full">
              <TableActionsMenu
                menuItems={[
                  {
                    label: 'View',
                    icon: <ViewAllIcon />,
                    onClick: () => {
                      metadataDrawer.onOpen();
                      setActiveRow(row);
                    },
                  },
                  {
                    label: 'Approve or Deny',
                    icon: <ManualIcon />,
                    onClick: () => {
                      setActiveRow(row);
                      approveDenyModal.onOpen();
                    },
                  },
                  {
                    label: 'View Policy Document',
                    icon: <PolicyIcon p={0.5} />,
                    onClick: () => {
                      setActiveRow(row);
                      policyDocumentModal.onOpen();
                    },
                  },
                ]}
              />
            </Box>
          );
        },
      },
    ];
  }, [
    requestAccount,
    handleAutoApprove,
    metadataDrawer,
    approveDenyModal,
    policyDocumentModal,
  ]);

  const getJitApproveRequests = useCallback(
    (page = INIT_PAGE_INFO) => {
      dispatch(actions.getJitApproveRequests({ q: {}, page }));
    },
    [dispatch],
  );

  const getRoleFiled = useCallback(row => {
    const details = row.access_request_details?.[row.cloud_type?.toLowerCase()];
    switch (row?.cloud_type) {
      case 'AWS':
        return { label: 'Role ARN', value: details.role };
      case 'GCP':
        return { label: 'Role ID', value: details.role_id };
      default:
        return {};
    }
  }, []);

  useEffect(() => {
    getJitApproveRequests();
  }, [getJitApproveRequests]);

  return (
    <Stack h="full" spacing={5}>
      <Box>
        <PageHeaderWithIcon
          label="JIT Requests"
          icon={<PermissionsActionsIcon />}
        />
      </Box>
      <Stack h="full" spacing={5} w="full">
        <Box flex={1}>
          <Table
            columns={columns}
            subComponent={({ row, expanded }) => {
              const roleField = getRoleFiled(row);

              return (
                expanded && (
                  <Stack px={10}>
                    <Stack w={'180px'}>
                      <Text>ADDITIONAL DETAILS</Text>
                      <Divider left={-4} pos={'relative'} />
                    </Stack>
                    <Stack spacing={4}>
                      <FieldValue
                        field={'Request received from'}
                        value={
                          <HStack>
                            <Square size={4}>
                              <SlackIcon />
                            </Square>
                            <Text>Slack channel (AriksaBot)</Text>
                          </HStack>
                        }
                      />

                      <FieldValue
                        field={'Approved by'}
                        value={
                          <HStack spacing={2}>
                            <HStack spacing={1}>
                              {row.approved_by?.map((a, i) => {
                                return (
                                  <Text>
                                    {a ?? '-'}
                                    {i === row.approved_by?.length - 1
                                      ? ''
                                      : ','}
                                  </Text>
                                );
                              })}
                            </HStack>
                          </HStack>
                        }
                      />
                      <FieldValue field={'Approved from'} value={''} />

                      {/*role arn*/}
                      <FieldValue
                        field={roleField.label ?? '-'}
                        value={roleField.value ?? '-'}
                      />
                      <FieldValue
                        field={'Permissions'}
                        value={
                          <HStack>
                            {filter(
                              highPrivilegesConst,
                              o => !!requestAccount(row)?.crud_counts?.[o],
                            ).map(o => {
                              return (
                                <Flex
                                  px={2}
                                  bg={getHighPrivilegeColor(o?.toLowerCase())}
                                  color="white"
                                  borderRadius={3}
                                  fontSize={12}
                                >
                                  {o} -{' '}
                                  {requestAccount(row)?.crud_counts?.[o] ?? 0}
                                </Flex>
                              );
                            })}
                          </HStack>
                        }
                      />
                      <FieldValue
                        field={'Resource Types'}
                        value={
                          <HStack>
                            {requestAccount(row)?.aws_resources?.map(r => (
                              <Tag label={r.resource_type} />
                            ))}
                          </HStack>
                        }
                      />
                      <FieldValue
                        field={'Approvers'}
                        value={
                          <HStack spacing={2}>
                            <HStack spacing={1}>
                              {row.approvers?.map((a, i) => {
                                return (
                                  <Text>
                                    {a ?? '-'}
                                    {i === row.approvers?.length - 1 ? '' : ','}
                                  </Text>
                                );
                              })}
                            </HStack>
                          </HStack>
                        }
                      />
                    </Stack>
                  </Stack>
                )
              );
            }}
            data={jitApproveRequests.data ?? []}
            pagination={{
              totalCount: jitApproveRequests.page.totalCount ?? 0,
              pageInfo: jitApproveRequests.page.info,
              onChange: getJitApproveRequests,
            }}
            isLoading={jitApproveRequests.isLoading}
            noDataMessage={<Center h={'160px'}>No access requests</Center>}
          />
        </Box>
      </Stack>
      {activeRow && approveDenyModal.isOpen && (
        <ApproveDenyModal
          request={activeRow}
          isOpen={approveDenyModal.isOpen}
          onClose={approveDenyModal.onClose}
        />
      )}
      {activeRow && policyDocumentModal.isOpen && (
        <PolicyDocumentModal
          request={activeRow}
          isOpen={policyDocumentModal.isOpen}
          onClose={policyDocumentModal.onClose}
        />
      )}
      {activeRow && autoApprove.isOpen && (
        <AutoApproveModal
          request={activeRow}
          isOpen={autoApprove.isOpen}
          onClose={autoApprove.onClose}
        />
      )}
      {activeRow && metadataDrawer.isOpen && (
        <ResourceActionsDrawer
          resource={{
            cloud_type: activeRow?.cloud_type ?? '',
            cloud_id: requestAccount(activeRow)?.cloud_account_id ?? '',
            name: requestAccount(activeRow)?.aws_username ?? '',
            type: NativeResources.IamUser,
            id: requestAccount(activeRow)?.account_id ?? '',
          }}
          isOpen={metadataDrawer.isOpen}
          onClose={metadataDrawer.onClose}
        />
      )}
    </Stack>
  );
};
